import axios from 'axios';
import React, { useState, useEffect } from 'react'
import logo from '../assets/logo.png'
import PhoneInput from 'react-phone-input-2'
import { FileUploader } from "react-drag-drop-files";
import 'react-phone-input-2/lib/style.css'
import '../assets/css/phone.css'

import MediaRelease from '../assets/Choohoo Final Media Release.pdf';
import Select from 'react-select';
import { Helmet } from 'react-helmet-async';

function Home() {
    const [fileError, setFileError] = useState(false);
    const [subsType, setSubsType] = useState('option1');
    const [gender, setGender] = useState('option1');
    const [capacity, setCapacity] = useState('option1');
    const [phone, setPhone] = useState();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [state, setState] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [fname, setFName] = useState();
    const [lname, setLName] = useState();
    const [email, setEmail] = useState();
    const [verifyEmail, setVerifyEmail] = useState();
    const [description, setDescription] = useState();
    const [weblink, setWeblink] = useState('');
    const [fblink, setFblink] = useState('');
    const [fbHook, setFBHook] = useState('');
    const [twitterHook, setTwitterHook] = useState('');
    const [instaHook, setInstaHook] = useState('');
    const [tiktokHook, setTiktokHook] = useState('');
    const [linkedInHook, setLinkedInHook] = useState('');

    const fileTypes = ["PDF"];
    const [file, setFile] = useState(null);


    const [formError, setFormError] = useState();
    const [formStatus, setFormStatus] = useState(sessionStorage.getItem('res'));

    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedState, setSelectedState] = useState();
    const [selectedCity, setSelectedCity] = useState();

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        axios.get(`https://www.choohoo.tech/country/data`)
            .then(res => {
                setCountries(res.data.output);
            })
            .then(err => {
                if (err !== undefined) {
                    console.log(err);
                }
            });
    }, []);

    const loadStates = (id) => {
        axios.get(`https://www.choohoo.tech/state/data?country_id=${id}`)
            .then(res => {
                setStates(res.data.output);
            })
            .then(err => {
                if (err !== undefined) {
                    console.log(err);
                }
            });
    }

    const loadCities = (id) => {
        axios.get(`https://www.choohoo.tech/city/data?country_id=${country.split(',')[0]}&state_id=${id}`)
            .then(res => {
                setCities(res.data.output);
            })
            .then(err => {
                if (err !== undefined) {
                    console.log(err);
                }
            });
    }

    const handleSubsTypeChange = (event) => {
        setSubsType(event.target.defaultValue);
    }

    const handleGenderChange = (event) => {
        setGender(event.target.defaultValue);
    }

    const handleCapacityChange = (event) => {
        setCapacity(event.target.defaultValue);
    }

    const handlePhoneChange = (event) => {
        setPhone(event)
    }

    const handleFileChange = (file) => {
        document.getElementById('msg').innerHTML = 'Drag & Drop Files Here<br/>or<br/><u>Browse Files</u>';
        setFile(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        document.getElementById('submit').disable = true;
        let filled = true;

        let formData = new FormData();

        if (subsType === 'option1') {
            formData.append('subscriber_type_id', 1);
        } else if (subsType === 'option2') {
            formData.append('subscriber_type_id', 2);
        } else {
            formData.append('subscriber_type_id', 3);
        }

        formData.append('first_name', fname);
        formData.append('last_name', lname);
        if (email === verifyEmail) {
            formData.append('email_address', verifyEmail);
        } else {
            setFormError('Email doesn\'t match !');
            filled = false;
        }

        formData.append('mobile_number', '+' + phone);

        if (country !== null) {
            formData.append('country_id', country.split(',')[0]);
        } else {
            setFormError('Country, State/Province & City Required');
            filled = false;
        }
        if (state !== null) {
            formData.append('state_id', state.split(',')[0]);
        } else {
            setFormError('Country, State/Province & City Required');
            filled = false;
        }
        if (city !== null) {
            formData.append('city_id', city.split(',')[0]);
        } else {
            setFormError('Country, State/Province & City Required');
            filled = false;
        }


        if (gender === 'option1') {
            formData.append('gender_id', 1);
        } else if (gender === 'option2') {
            formData.append('gender_id', 2);
        } else {
            formData.append('gender_id', 3);
        }

        if (capacity === 'option1') {
            formData.append('organisation_type_id', 1);
        } else if (capacity === 'option2') {
            formData.append('organisation_type_id', 2);
        } else {
            formData.append('organisation_type_id', 3);
        }

        formData.append('organisation_description', description);


        if (weblink === '') {
            formData.append('website_address', null);
        } else {
            formData.append('website_address', weblink);
        }

        if (fblink === '') {
            formData.append('facebook_page', null);
        } else {
            formData.append('facebook_page', fblink);
        }

        if (fbHook === '') {
            formData.append('facebook_handle', null);
        } else {
            formData.append('facebook_handle', fbHook);
        }

        if (twitterHook === '') {
            formData.append('twitter_handle', null);
        } else {
            formData.append('twitter_handle', twitterHook);
        }

        if (instaHook === '') {
            formData.append('instragram_handle', null);
        } else {
            formData.append('instragram_handle', instaHook);
        }

        if (tiktokHook === '') {
            formData.append('tiktok_handle', null);
        } else {
            formData.append('tiktok_handle', tiktokHook);
        }

        if (linkedInHook === '') {
            formData.append('linkedin_handle', null);
        } else {
            formData.append('linkedin_handle', linkedInHook);
        }

        if (file !== null) {
            formData.append('profile_document', file);
        } else {
            formData.append('profile_document', null);
        }

        if (filled) {
            axios.post(`https://choohoo.tech/api/subscriber/form`, formData)
                .then(res => {
                    if (res !== undefined) {
                        sessionStorage.setItem('res', 'Thank you for contacting Choohoo. Please check your email for your reference number.');
                        setFormStatus('Thank you for contacting Choohoo. Please check your email for your reference number.');
                        reset();
                    }

                })
                .then(err => {
                    if (err !== undefined) {
                        setFormError(err.data.message);
                    }
                });
        }

    }

    const reset = async () => {
        setFName('');
        setLName('');
        setEmail('');
        setVerifyEmail('');
        setPhone('');
        setCountry(null);
        setState(null);
        setCity(null);

        setSelectedCountry(null);
        setSelectedState(null);
        setSelectedCity(null);

        setSubsType('option1');
        setGender('option1');
        setCapacity('option1');
        setDescription('');
        setWeblink('');
        setFblink('');
        setFBHook('');
        setTwitterHook('');
        setInstaHook('');
        setTiktokHook('');
        setLinkedInHook('');
        setFile('');
    }

    return (
        <div>
            <Helmet>
                <title>Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits.</title>
                <meta name="description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
                <meta property="og:title" content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits." />
                <meta property="og:description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
            </Helmet>
            
            <div className='flex content-center justify-center'>
                <a href='/'>
                    <img src={logo} alt='logo' className='w-52 mt-10' />
                </a>
            </div>

            <div className='w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto'>

                <div className='mt-16 text-center font-light text-[22px] md:text-2xl lg:text-[26px]'>
                    <p className='ml-5 mr-5'>A <b className='font-[550]'>Shared Marketplace</b> that <b className='font-[550]'>Rewards You</b>, creative work and public benefits.</p>
                    <br />
                    <p>
                        Before the app and platform launches (soon), we invite individuals, groups and organisations to
                        <br />
                        <b className='font-[550]'>be part of the product evolution…</b>
                    </p>
                    <br />
                    <p>Partner with the <b className='font-[550]'>world’s first SFT marketplace.</b></p>
                    <br />
                    <p>
                        Choohoo sells your SFTs (digital content) i.e. curated images, audio, video, written work, and other digital assets.
                        A portion of all sales goes to artists and creatives, donations to public benefits (social causes), and as commissions revenue to marketing partners & side-hustlers.
                    </p>
                </div>

                <div className='mt-4 xl:mt-8'>
                    <iframe className='w-full h-[163px] md:h-[388px] lg:h-[523px] xl:h-[624px]' src="https://www.youtube.com/embed/aVOA1Nl6Ovw" title="Choohoo | Join our Product Evolution | a Shared Marketplace" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>

                <div className='text-center mt-11 text-[#ebf411] text-[22px] font-light md:text-[24px] lg:text-[26px] lg:w-3/5 xl:w-2/4 m-auto'>
                    <p>
                        Join the evolution of this <b className='font-[550]'>global eco-system,</b> and the <b className='font-[550]'>opportunity for shared revenue.</b>
                    </p>
                </div>

                <div className='mt-11 text-center text-[18px] md:text-[22px] font-normal' id='form'>
                    <p>
                        Fill in the form and we will contact you to discuss further details
                    </p>
                </div>

                <div className="mt-11 text-[18px] md:text-[20px] font-light w-full flex">
                    <div className="w-1/3 md:w-1/6">Choose 1</div>
                    <div className="w-3/4 md:w-10/12">if you want to have your creative work sold.</div>
                </div>
                <div className="mt-3 text-[18px] md:text-[20px] font-light w-full flex">
                    <div className="w-1/3 md:w-1/6">Choose 2</div>
                    <div className="w-3/4 md:w-10/12">
                        if you do social cause work, and would like to receive a donation.
                    </div>
                </div>
                <div className="mt-3 text-[18px] md:text-[20px] font-light w-full flex">
                    <div className="w-1/3 md:w-1/6">Choose 3</div>
                    <div className="w-3/4 md:w-10/12">
                        if you would like to receive a commission for providing marketing and sales services.
                    </div>
                </div>

                {/* Subscription Form */}

                <div className='mt-11 w-4/5 md:w-4/6 m-auto'>
                    <form onSubmit={handleSubmit} id='form'>
                        <div className="radio flex content-center">
                            <input type="radio" value="option1" checked={subsType === 'option1'} onChange={handleSubsTypeChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                            <label className='text-[16px] md:text-[22px] pt-1'>
                                1. Artist | Innovator | Creative
                            </label>
                        </div>
                        <div className="radio flex content-center mt-5">
                            <input type="radio" value="option2" checked={subsType === 'option2'} onChange={handleSubsTypeChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                            <label className='text-[16px] md:text-[22px] pt-1'>
                                2. Public Benefit | Social Cause
                            </label>
                        </div>
                        <div className="radio flex content-center mt-5">
                            <input type="radio" value="option3" checked={subsType === 'option3'} onChange={handleSubsTypeChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                            <label className='text-[16px] md:text-[22px] pt-1'>
                                3. Marketing Partner | Side-Hustler | Promotions
                            </label>
                        </div>

                        <div className='mt-11'>
                            <input type='text' id='fname' value={fname} onChange={(e) => { setFName(e.target.value) }} required className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white' placeholder='First Name*' />

                            <input type='text' id='lname' value={lname} onChange={(e) => { setLName(e.target.value) }} required className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Last Name*' />

                            <input type='email' id='email' value={email} onChange={(e) => { setEmail(e.target.value) }} required className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Email*' />

                            <input type='email' id='vemail' value={verifyEmail} onChange={(e) => { setVerifyEmail(e.target.value) }} required className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Enter email again to confirm*' />

                            <PhoneInput country={'us'} inputProps={{ name: 'phone', required: false }} value={phone} onChange={handlePhoneChange} inputClass='container' dropdownClass='dropdown-class' buttonClass='dropdown-button' containerClass='parent' />

                            <div className='selector'>
                                <Select
                                    options={countries}
                                    placeholder='Country*'
                                    onChange={opt => {
                                        setCountry(opt.value + ',' + opt.label);
                                        loadStates(opt.value);

                                        countries.forEach((country) => {
                                            if(country.country_id === opt.value){
                                                setSelectedCountry(country);
                                            }
                                        });

                                    }}
                                    required={true}
                                    value={selectedCountry}
                                />
                            </div>

                            <div className='selector'>
                                <Select
                                    options={states}
                                    placeholder='State/Province*'
                                    onChange={opt => {
                                        setState(opt.value + ',' + opt.label);
                                        loadCities(opt.value);

                                        states.forEach((state) => {
                                            if(state.state_id === opt.value){
                                                setSelectedState(state);
                                            }
                                        });
                                    }}
                                    required={true}
                                    noOptionsMessage={() => {
                                        return 'Please select a State/Province';
                                    }}
                                    value={selectedState}
                                />
                            </div>

                            <div className='selector'>
                                <Select
                                    options={cities}
                                    placeholder='City*'
                                    onChange={opt => {
                                        setCity(opt.value + ',' + opt.label);

                                        cities.forEach((city) => {
                                            if(city.city_id === opt.value){
                                                setSelectedCity(city);
                                            }
                                        });
                                    }}
                                    required={true}
                                    noOptionsMessage={() => {
                                        return 'Please select a City';
                                    }}
                                    value={selectedCity}
                                />
                            </div>
                        </div>

                        <br />
                        <div className='mt-12'>
                            <label className='text-[16px] md:text-[22px] font-light text-white'>Gender</label>
                            <div className="radio flex content-center mt-4">
                                <input type="radio" value="option1" checked={gender === 'option1'} onChange={handleGenderChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Male
                                </label>
                            </div>
                            <div className="radio flex content-center mt-5">
                                <input type="radio" value="option2" checked={gender === 'option2'} onChange={handleGenderChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Female
                                </label>
                            </div>
                            <div className="radio flex content-center mt-5">
                                <input type="radio" value="option3" checked={gender === 'option3'} onChange={handleGenderChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Non-binary
                                </label>
                            </div>
                        </div>

                        <div className='mt-11'>
                            <label className='text-[16px] md:text-[22px] font-light text-white'>Are you interested in an individual or organisational capacity?</label>
                            <div className="radio flex content-center mt-4">
                                <input type="radio" value="option1" checked={capacity === 'option1'} onChange={handleCapacityChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Individual
                                </label>
                            </div>
                            <div className="radio flex content-center mt-5">
                                <input type="radio" value="option2" checked={capacity === 'option2'} onChange={handleCapacityChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Group | Collective | Cooperative
                                </label>
                            </div>
                            <div className="radio flex content-center mt-5">
                                <input type="radio" value="option3" checked={capacity === 'option3'} onChange={handleCapacityChange} className='mr-4 w-10 h-10 accent-[#ebf411]' />
                                <label className='text-[16px] md:text-[22px] pt-1'>
                                    Organisation | Company
                                </label>
                            </div>
                        </div>

                        <div className='mt-11'>
                            <textarea type='text' id='description' value={description} onChange={(e) => { setDescription(e.target.value) }} required className='h-28 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white' placeholder='Briefly tell us what you or your organisation does?*' />
                        </div>

                        <div className='mt-11'>
                            <label className='text-[16px] md:text-[22px] font-light text-white'>Where can we find more information about you and/or your work? (if applicable)</label>

                            <input type='text' id='weblink' value={weblink} onChange={(e) => { setWeblink(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-11' placeholder='Website Address Link' />

                            <input type='text' id='fblink' value={fblink} onChange={(e) => { setFblink(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Facebook Page Link' />

                            <input type='text' id='fbhook' value={fbHook} onChange={(e) => { setFBHook(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Facebook handle @Name' />

                            <input type='text' id='twitterhook' value={twitterHook} onChange={(e) => { setTwitterHook(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Twitter handle @Name' />

                            <input type='text' id='instahook' value={instaHook} onChange={(e) => { setInstaHook(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Instagram handle @Name' />

                            <input type='text' id='tiktokhook' value={tiktokHook} onChange={(e) => { setTiktokHook(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Ticktok handle @Name' />

                            <input type='text' id='linkedinhook' value={linkedInHook} onChange={(e) => { setLinkedInHook(e.target.value) }} className='h-12 bg-black text-[16px] md:text-[22px] pl-5 block p-4 w-full text-white rounded-3xl border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-gray-500 dark:bg-black dark:border-[#ebf411] dark:placeholder-white dark:text-white mt-5' placeholder='Linkedin handle @Name' />
                        </div>

                        <div className='mt-11'>
                            <label className='text-[16px] md:text-[22px] font-light text-white'>If you have a PDF Profile (max. 5 MB), please also attach it. (Pdfs from your icloud or google drive, Must be downloaded to you device before upload)</label>
                            <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} label='Upload or Drag & Drop Files Here' hoverTitle='Drop Here' maxSize={5} classes='drop-container drop_zone' onSizeError={() => { setFile(null); document.getElementById('msg').innerHTML = 'File size/type error. <br/>Please attach PDF, <u>click here</u> or drag and drop'; }} onTypeError={() => { setFile(null); document.getElementById('msg').innerHTML = 'File size/type error. <br/>Please attach another PDF'; }} />
                            <p id='msg' className={`${fileError ? 'hidden' : 'visible'}text-white text-center relative -mt-24 content-center justify-center`}>Drag & Drop Files Here<br />or<br /><u>Browse Files</u></p>
                            <p className={`${file ? 'visible' : 'hidden '} text-white mt-4`}>{file ? `File name: ${file.name}` : "No Files Selected Yet"}</p>
                        </div>

                        <div className='mt-20 text-[18px] md:text-[26px] bg-[#ebf411] w-24 md:w-32 p-3 flex content-center justify-center m-auto rounded-full text-black mr-0'>
                            <button type='submit' className='tracking-[0px]' id='submit'>SUBMIT</button>
                        </div>
                    </form>

                    <div className='mt-8 text-[16px] w-full flex content-center justify-center m-auto rounded-lg'>
                        <p className={`${formError ? 'visible' : 'hidden'} border-2 border-dashed border-slate-500 p-3 text-red-300 text-center`}>{formError}</p>
                        <p className={`${formStatus ? 'visible' : 'hidden'} border-2 border-dashed border-slate-500 p-3 text-white text-center`}>{formStatus} <br />Have a super day!</p>
                    </div>
                </div>

                <div className='mt-11 text-center text-[22px] md:text-[26px] font-light' id='sft'>
                    <p>What are <b className='font-[550]'>SFTs?</b></p>
                </div>

                <div className='mt-5 mb-11 text-left text-[20px] font-light'>

                    <p>
                        A Shared-Fungible Token (SFT) is a multiple-of-a-kind digital asset or digital content that belongs to whoever purchases it. SFTs include images, video, audio, written work and other innovative products and content.
                    </p>
                    <br />

                    <p>
                        SFTs are the opposite of NFTs, and the digital equivalent of the official copies of the original content. Just like official copies of creative works are much cheaper than the original, SFTs can be cheaper than NFTs while still providing an impact as per their intended purpose.
                    </p>
                    <br />

                    <p>
                        Only a few people in the world might be able to afford NFTs, but SFTs can bridge the gap for those who also want high-quality digital content and assets at fairly affordable prices.
                    </p>
                    <br />

                    <p>
                        SFTs are not cryptocurrency. SFTs are a more democratised alternative to NFTs.
                    </p>
                    <br />

                    <p>
                        All copyright and intellectual property of the SFT content supplied by the originator/s is retained by the originator/s, and not Choohoo.
                    </p>

                </div>

                <div className='mt-20 text-center text-[22px] font-light md:text-[26px]' id='marketplace'>
                    <p>
                        Why is Choohoo a <b className='font-[550]'>Global Shared Marketplace?</b>
                    </p>
                    <p className='mt-4'>
                        Portions of  <b className='font-[550]'>All Sales are Shared:</b>
                    </p>
                </div>

                <div className='mt-5 text-[22px] md:text-[24px] lg:text-[26px] font-light'>
                    <p>
                        1. as revenue to creatives, artists or innovators
                    </p>
                    <p className='mt-4'>
                        2. as donations to community public benefits (social causes)
                    </p>
                    <p className='mt-4'>
                        3. as commissions revenue to marketing partners & side-hustlers, creating +4000 jobs
                    </p>
                    <p className='mt-4'>
                        4. as a portion that Choohoo is keeping secret until launch
                    </p>
                </div>
                <br/><br/>
                <div className='mt-4 text-[22px] md:text-[24px] lg:text-[26px] font-light'>
                    <p>
                        There is a contract process for all partners and providers like yourself, which includes curation, vetting and KYC requirements. All copyright and intellectual property of the SFT content supplied by the originator/s is retained by the originator/s, and not Choohoo.
                    </p>
                </div>
                <br/><br/>
                <div className='mt-4 text-[22px] md:text-[24px] lg:text-[26px] font-light'>
                    <p >
                    <b className='font-[550]'>Read Choohoo Media Release</b> <a className='text-[#ebf411]' target='_blank' href={MediaRelease} rel="noreferrer">here</a>
                    </p>
                </div>

                <div className='mt-16 mb-8 text-[14px] text-center font-light'>
                    <p>Copyright © CHOOHOO (PTY) LTD 2022. All Rights Reserved. Cape Town, South Africa.</p>
                </div>

            </div>
        </div>
    )
}

export default Home;