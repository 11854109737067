import React from 'react'
import { Helmet } from 'react-helmet-async';
import logo from '../assets/logo.png'

function Terms() {
    return (
        <div>
            <Helmet>
                <title>Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits.</title>
                <meta name="description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
                <meta property="og:title" content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits." />
                <meta property="og:description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
            </Helmet>
            <div className='flex content-center justify-center'>
                <a href='/'>
                    <img src={logo} alt='logo' className='w-52 mt-10' />
                </a>
            </div>

            <div className='w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto'>
                <div className='mt-16'>
                    <p className='font-extrabold text-[2rem]'>
                        CHOOHOO PLATFORM TERMS AND CONDITIONS OF USE
                    </p>
                </div>

                <div className='mt-11'>
                    <div className="mt-11 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">1.</div>
                        <div className="w-11/12">These Terms and Conditions (“<b className='font-semibold'>Terms of Use/Terms</b>”) govern the use of the Choohoo website application/app at <a href='www.choohoo.world' className='text-blue-600' target='blank'>www.choohoo.world</a> and the Choohoo mobile application/app (collectively referred to as the “<b className='font-semibold'>Choohoo Platform/Platform</b>”) including but limited to the use of our online revenue-share marketplace to purchase curated creative/innovative/artistic digital content or digital assets (also known as “<b className='font-semibold'>SFTs</b>”), or participation in the revenue-sharing opportunities such as creative/innovative/artist partnerships, public benefit partnerships or sales and marketing partnerships with Choohoo (Proprietary) Limited (“<b className='font-semibold'>Choohoo/we/us/our</b>”).</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">2.</div>
                        <div className="w-11/12">For the avoidance of doubt, SFTs are Shared-Fungible Tokens and are <b className='font-semibold'>NOT cryptocurrency nor built of/on blockchain technology</b>. Neither is the Choohoo Platform built on/of cryptocurrency or blockchain technology.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">3.</div>
                        <div className="w-11/12">These Terms of Use are binding and enforceable against every person that accesses or uses our Platform (“<b className='font-semibold'>you</b>”, “<b className='font-semibold'>your</b>” or “<b className='font-semibold'>user</b>”), including but not limited to each user who registers as contemplated below (“<b className='font-semibold'>registered user/participant</b>”). <b className='font-semibold'>By using the Platform and by clicking on the “Sign-up” button on the Platform, as may be applicable, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. If you do not agree with all of these Terms of use, then you are expressly prohibited from using the Platform and you must discontinue use immediately.</b></div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">4.</div>
                        <div className="w-11/12">Supplemental terms and conditions or documents that may be posted separately on the Platform from time to time are expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you on the Platform of any material changes (changes which go to the heart of the services) to these Terms. We will also alert you that changes have been made by updating the “Last updated” date of these Terms. Other than via the aforesaid alerts, you waive any right to receive notification of each such change. Please ensure that you check the Terms of Use every time you use our Platform so that you understand the Terms of Use which apply to your use of the Platform. <b className='font-semibold'>You will be subject to, and will be deemed to have been made aware of and to have accepted the changes in any revised Terms of Use by your continued use of the Platform after the date that such revised Terms are posted on the Platform.</b></div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.</div>
                        <div className="w-11/12"><b className='font-semibold'>Important Notice for purposes of the Consumer Protection Act No. 68 of 2008 as amended from time to time (the “CPA”)</b>.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.1</div>
                        <div className="w-11/12">These Terms apply to users who are consumers as defined in the CPA.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.2</div>
                        <div className="w-11/12"><b className='font-semibold'>These Terms may contain provisions that are bolded in the style/font as used in this clause and which - </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.2.1</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>may limit the risk or liability of Choohoo or a third party;</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.2.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>may create risk or liability for the user;</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.2.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>may compel the user to indemnify Choohoo or a third party; and/or</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.2.4</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>may serve as an acknowledgement, by the user, of a fact.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.3</div>
                        <div className="w-11/12"><b className='font-semibold'>Your attention is drawn to these Terms because they must be very carefully noted by you.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.4</div>
                        <div className="w-11/12"><b className='font-semibold'>If there is any provision in these Terms that you do not understand, it is your responsibility to ask Choohoo to explain it to you before you accept these Terms or continue using the Platform.</b></div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">5.5</div>
                        <div className="w-11/12">Nothing in these Terms is intended or must be understood to unlawfully prohibit, limit or avoid any right or obligation, as the case may be, created for either you or Choohoo in terms of the CPA.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.</div>
                        <div className="w-11/12"><b className='font-semibold'>Registration and use of the Platform</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.1</div>
                        <div className="w-11/12 pl-8">Only registered users may purchase SFTs on the Platform.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2</div>
                        <div className="w-11/12 pl-8">To register as a user, the compulsory information required is:</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.1</div>
                        <div className="w-11/12 pl-16">your first and last name;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.2</div>
                        <div className="w-11/12 pl-16">a username (your chosen email address); </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.3</div>
                        <div className="w-11/12 pl-16">a password to be created by you;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.4</div>
                        <div className="w-11/12 pl-16">a security question as selected by you from the dropdown list on the Platform along with a suitable answer as chosen by you;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.5</div>
                        <div className="w-11/12 pl-16">the country, province/state and city you reside in;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.6</div>
                        <div className="w-11/12 pl-16">your mobile number;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.7</div>
                        <div className="w-11/12 pl-16">your date of birth;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.8</div>
                        <div className="w-11/12 pl-16">your gender – Male, Female or Non-Binary;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.2.9</div>
                        <div className="w-11/12 pl-16">your agreement to Choohoo’s Platform Terms of Use including Choohoo’s Privacy Policy;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.3</div>
                        <div className="w-11/12">Optional information for registration is:</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.3.1</div>
                        <div className="w-11/12 pl-16">your physical address</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.3.2</div>
                        <div className="w-11/12 pl-16">your nickname or alias;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.3.3</div>
                        <div className="w-11/12 pl-16">a photo for your Choohoo profile; and </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.3.4</div>
                        <div className="w-11/12 pl-16">your social media handles.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.4</div>
                        <div className="w-11/12">On completion of registration, the Platform will issue you with a unique Choohoo identification number (“<b className='font-semibold'>Choohoo ID</b>”). All the above compulsory and optional information is stored under your Choohoo profile and accessed via your Choohoo ID. Your Choohoo ID is utilised for security and verification processes where applicable.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.5</div>
                        <div className="w-11/12">You will need to use your username and password to login and access the Platform in order to purchase SFTs or to apply for partnership opportunities with Choohoo. </div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.6</div>
                        <div className="w-11/12">You undertake and warrant that your chosen username and password shall:</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.6.1</div>
                        <div className="w-11/12 pl-16">be used for personal use only; and</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.6.2</div>
                        <div className="w-11/12 pl-16">not be disclosed by you to any third party.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.7</div>
                        <div className="w-11/12">For security purposes you are required to enter the correct username and password whenever ordering SFTs, failing which you will be denied access.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.8</div>
                        <div className="w-11/12"><b className='font-semibold'>Once the correct username and password relating to your account have been entered, irrespective of whether the use of the username and password is unauthorised or fraudulent, you will be liable for payment of such purchases/orders.</b></div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.9</div>
                        <div className="w-11/12">You will notify Choohoo immediately upon becoming aware of or reasonably suspecting any unauthorised access to or use of your username and password and to take steps to mitigate any resultant loss or harm.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.10</div>
                        <div className="w-11/12"><b className='font-semibold'>You must be at least 14 (fourteen) years of age to use our Platform. If you are under the age of 18 (eighteen) or if you are not legally permitted to enter into a binding agreement, you may use the Platform only with the involvement and supervision of your parent or legal guardian. If your parent or legal guardian accordingly supervises and gives his/her consent, then he/she agrees to be bound to these Terms and to be liable and responsible for you and all your obligations under these Terms.</b></div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.11</div>
                        <div className="w-11/12">You will not in any way use any device, software or other instrument to interfere or attempt to interfere with the proper working of the Platform. In addition, you will not in any way use any robot, spider, other automatic device, or manual process to monitor, copy, distribute or modify the Platform or the information contained therein, without the prior written consent of Choohoo’s Chief Executive Officer (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to the Platform).</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.12</div>
                        <div className="w-11/12">You may not use the Platform to distribute material which is defamatory, offensive, contains or constitutes hate speech or is otherwise unlawful.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">6.13</div>
                        <div className="w-11/12">Unless any specific “share” button/s have been provided on the Platform, you may not in any way display, publish, copy, print, post or otherwise use the Platform and/or the information contained therein without the express prior written consent of Choohoo’s Chief Executive Officer.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">7.</div>
                        <div className="w-11/12"><b className='font-semibold'>Conclusion of sales</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">7.1</div>
                        <div className="w-11/12 pl-8">Registered users may purchase SFTs which are for sale on the Platform.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">7.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>An agreement of sale between you and Choohoo comes into effect once your payment has been received in terms of clause 8 below and Choohoo makes the SFT downloadable by you via a functional and unbroken Choohoo magic link on the Platform or as as emailed to you. (A magic link is a temporary, unique link created each time you click the “generate magic link” button to download an SFT that you have purchased). </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">7.3</div>
                        <div className="w-11/12 pl-8">Should the magic link due to technical error be broken, click the “generate magic link” button until the magic link issues the SFT download. In the event that a new magic link cannot be generated or is continuously broken, complete the query form provided on the Platform to inform Choohoo of the technical error.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">7.4</div>
                        <div className="w-11/12 pl-8">Once SFTs are purchased you may not re-sell them on the Platform. </div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.</div>
                        <div className="w-11/12"><b className='font-semibold'>Payment</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1</div>
                        <div className="w-11/12 pl-8">There are 2 (two) ways to pay for or acquire SFTs, namely (i) by using the Paystack payment gateway or (ii) by using Gift Vouchers or Codes as set out in clause 11 below.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.1</div>
                        <div className="w-11/12 pl-16">You may pay via the Paystack payment gateway using credit card, debit card, EFT (Electronic Funds Transfer), Masterpass or Snapscan.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.2</div>
                        <div className="w-11/12 pl-16">We are committed to providing secure online payment facilities. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.3</div>
                        <div className="w-11/12 pl-16">All transactions are encrypted using appropriate encryption technology.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.4</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>You warrant that you are fully authorised to use the credit card or debit card supplied for purposes of paying for the SFTs. You warrant that your credit card has sufficient available funds to cover all the costs incurred as a result of the services used on the Platform;</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.5</div>
                        <div className="w-11/12 pl-16">Once you have entered your credit / debit card details, we, Paystack and your bank may require additional information in order to authorise and/or verify the validity of payment. In such cases we may withhold delivery of a magic link to you until such time as the additional information is received by us, your bank and Paystack, and authorisation is obtained by us for the relevant amount. If we do not receive authorisation, your order and purchase will be cancelled.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">8.1.6</div>
                        <div className="w-11/12 pl-16">The complete history of your payments made on the Platform can be accessed via your “Receipts” menu on the Platform.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">9.</div>
                        <div className="w-11/12"><b className='font-semibold'>Delivery of SFTs</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">9.1</div>
                        <div className="w-11/12 pl-8">Choohoo’s obligation to deliver an SFT to you is fulfilled when we make the SFT available to you for successful download by giving you access to the “generate magic link” button for that specific SFT. The complete history and access to download your SFTs can be found in your “My SFTs” menu on the Platform.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">9.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo is not responsible for any loss or unauthorised use of the SFT, once it has been downloaded by you.</b> However, you can always re-download your SFT via your “My SFTs” menu.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">10.</div>
                        <div className="w-11/12"><b className='font-semibold'>Transactions based on errors</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">10.1</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>We shall take all reasonable efforts to accurately reflect the description, availability, purchase price of SFTs on the Platform.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">10.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>However, should there be any errors of whatsoever nature on a Platform which are not due to our wilful misconduct or gross negligence, we shall not be liable for any loss, claim or expense relating to a transaction based on such error.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">10.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo shall not be bound by any incorrect information regarding our SFTs displayed on any third party websites.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.</div>
                        <div className="w-11/12"><b className='font-semibold'>Codes & Gift Vouchers</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1</div>
                        <div className="w-11/12 pl-8">Choohoo may from time to time make electronic gift vouchers (“Gift Vouchers”) and certain types of Codes as detailed in this clause 11 below available for use on the Platform for the purchase of SFTs. Gift Vouchers and Codes can only be redeemed while they are valid and their expiry dates cannot be extended. More specifically:</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]"></div>
                        <div className="w-11/12 pl-4"><b className='font-semibold'>Codes</b></div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.1</div>
                        <div className="w-11/12 pl-16">Special Codes:
                            <br /><br />
                            The Choohoo system may issue qualifying participants a code known as a Special Code after every 4th (fourth) purchase on the Choohoo Platform as well as on their birthdays, which qualifying participants can use to acquire SFTs on the Platform. Special Codes may be issued directly on the Platform and via email to the qualifying participant and are valid for 6 (six) months from the date of issue. The value of the Special Codes will be determined by Choohoo in its sole discretion and can be changed by Choohoo in its sole discretion from time to time. Using a Special Code to acquire an SFT also grants participants a Money-Reward Opportunity. Special Codes are not transferable, do not accrue interest and are not redeemable for cash.
                        </div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.2</div>
                        <div className="w-11/12 pl-16">Super Codes:
                            <br /><br />
                            Choohoo may issue codes known as Super Codes to selected participants on the Platform from time to time for whatever reason Choohoo deems necessary, which the selected participants can use to acquire SFTs on the Platform. Super Codes are valid for 6 (six) months from date of issue. For instance, Choohoo may issue Super Codes in the scenarios as set out in the Returns Policy at clause 12 below. Using a Super Code to acquire an SFT also grants participants a Money-Reward Opportunity. Super Codes are not transferable, do not accrue interest and are not redeemable for cash.
                        </div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.3</div>
                        <div className="w-11/12 pl-16">Promotional/Promo Codes:
                            <br /><br />
                            Choohoo may issue Participants with Promo Codes to invite more people to register as users with Choohoo. Each time a participant shares its Choohoo Promo Code with 10 (ten) people who then sign-up and become registered users on the Choohoo Platform referencing that specific Promo Code, that participant’s Promo Code becomes “greenlit” for use, in other words that Promo Code can then be used by that participant to acquire SFTs on the Platform. Using a Super Code to acquire an SFT also grants participants a Money-Reward Opportunity Promo Codes are valid for a period of  6 (six) months from the date that they are marked as “greenlit” by the Choohoo system.  Promo Codes are not transferable, do not accrue interest and are not redeemable for cash.
                        </div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.4</div>
                        <div className="w-11/12 pl-16">As a general rule, and unless specified otherwise on the Platform:</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.1</div>
                        <div className="w-11/12 pl-16">Codes can only be used by the participant it is issued to;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.2</div>
                        <div className="w-11/12 pl-16">a Code can only be used once;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.3</div>
                        <div className="w-11/12 pl-16">only one Code can be used per SFT4– however multiple Codes can be used for multiple SFTs in an SFT Pod or multiple SFT Pods;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.4</div>
                        <div className="w-11/12 pl-16">the value of the Code will be set off against the value of the SFT purchase and the balance remaining, if any, will be payable by you;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.5</div>
                        <div className="w-11/12 pl-16">11.1.4.5	Codes cannot be used to buy Gift Vouchers or other Codes, and cannot be exchanged or refunded for cash or credit. <b className='font-semibold'>Choohoo is not responsible for any harm due to the loss, unauthorised use or distribution of any Codes;</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.6</div>
                        <div className="w-11/12 pl-16">if for any reason a Code does not reflect on the final amount due from you at check-out, you can contact us <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a> to confirm if the Code is still valid.  If Choohoo confirms that the Code is still valid and you have already placed your order, you can place the order again with the Code, or you can use the Code on your next order within the limitations of these Terms and any specific Code terms and conditions as published on the Platform from time to time;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.7</div>
                        <div className="w-11/12 pl-16">you may be required to submit the original communication containing the Code, and any other information reasonably requested by Choohoo, before you are able to use a Code; and</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex ml-6">
                        <div className="w-[10%] md:w-[5%]">11.1.4.8</div>
                        <div className="w-11/12 pl-16">instructions on how to redeem the Codes may be set out on the Platform and may be updated from time to time.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]"></div>
                        <div className="w-11/12 pl-4"><b className='font-semibold'>Gift Vouchers</b></div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.5</div>
                        <div className="w-11/12 pl-16">Gift Vouchers that are purchased by registered users are valid for 6 (six) months from date of purchase. Gift Vouchers that Choohoo gives away for free are valid for the period stated thereon. In each case, if your Voucher has not been used within that period, it will expire.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.6</div>
                        <div className="w-11/12 pl-16">Gift Vouchers cannot be used to buy other Gift Vouchers or Codes. They do not accrue interest and are not refundable for cash once purchased or otherwise obtained. If your Gift Voucher value is less than the amount required to cover the full order you wish to place, you may make up the difference by paying via one of our other payment methods.</div>
                    </div>
                    <div className="mt-4 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">11.1.7</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Choohoo is not responsible for any harm due to the loss, unauthorised use or unauthorised distribution of a Gift Voucher, after it has delivered the Gift Voucher to you on the Platform or via email or any other official communication partner platform.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">12.</div>
                        <div className="w-11/12"><b className='font-semibold'>Returns Policy</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">12.1</div>
                        <div className="w-11/12 pl-8">In the event that a registered user has purchased an SFT, but the magic link cannot be generated or is continuously broken, Choohoo may issue the user with a Super Code to the value of the SFT purchased to acquire another SFT or the same SFT on the Platform. Refunds do not apply.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">12.2</div>
                        <div className="w-11/12 pl-8">In the event that a registered user has downloaded an SFT but finds technical fault with the SFT, the user must complete the query form on the Platform to explain the technical fault. Choohoo will consider the query and determine whether it can resolve the technical fault so that the user may re-download the SFT free of fault or issue the user with a Super Code to acquire another SFT or the same SFT on the Platform. Refunds do not apply.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">12.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>In the event that Choohoo, for purposes of preventing suspected fraud or suspected abuse of the Platform, bans the user from its database, as detailed in clause 21.5 below, refusing to, amongst other things, accept or process payment on any order, and provided the user has not yet received the magic link for the SFT, Choohoo will refund monies already paid by the user and disqualify the user from any Money-Reward Opportunity as referred to in clause 13.2 below.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">12.4</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>In the event of a force majeure ( i.e. an act of God) such as a civil war, natural disaster (earthquake, flood etc.), or other circumstances that create damage or downtime on the Choohoo technology and systems to the extent that Choohoo is unable to provide SFTs that users have already paid for, Choohoo will refund such monies, pending the availability of the systems to do so.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.</div>
                        <div className="w-11/12"><b className='font-semibold'>Promotional competitions and campaigns</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.1</div>
                        <div className="w-11/12 pl-8">Choohoo may run various promotional activities from time to time, including in-App/website promotional competitions and campaigns. Choohoo, as part of its revenue share marketplace offering, currently runs a promotional competition known as a “Money-Reward Opportunity”. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>The Money Reward Opportunity Promotional competition (“MRO”) terms and conditions are as follows:</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.1</div>
                        <div className="w-11/12 pl-16">The Choohoo system automatically gives all registered users who have purchased the same SFT(s) of same SFT Pod (i.e. digital information folder holding all the details of that SFT) on the Platform an opportunity to be rewarded, by random selection, a percentage of all sales generated and recorded for that particular SFT in its SFT Pod over a set period of time, as set out in more detail here below (i.e. a monetary sum referred to as the Money-Reward).</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.2</div>
                        <div className="w-11/12 pl-16">By purchasing an SFT you are deemed to have agreed to these MRO terms and conditions.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.3</div>
                        <div className="w-11/12 pl-16">Once you have purchased an SFT of the same SFT Pod, you will automatically be entered into the MRO for that particular SFT Pod. Any additional purchases constitute additional entries into that MRO. For example, 3 (three) SFT purchases in a particular SFT Pod equates to 3 (three) entries into that MRO. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.4</div>
                        <div className="w-11/12 pl-16">Each SFT Pod stores the information of one creative work and MRO only. The same SFT Pod will never hold details and sales data of more than one creative work and MRO. A separate MRO will be run per SFT Pod.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.5</div>
                        <div className="w-11/12 pl-16">The period per MRO will be 14 (fourteen) calendar days. Each SFT Pod has its own opening and closing time for the MRO that applies to it. Opening and closing times may differ per SFT Pod. In other words, the start and end time of MROs may differ. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.6</div>
                        <div className="w-11/12 pl-16">The Money-Reward value may differ per SFT Pod as determined by the Choohoo algorithm and system. The value of the Money Reward for each MRO is displayed in its SFT Pod on the Platform.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.7</div>
                        <div className="w-11/12 pl-16">One minute after the closing time of an SFT Pod, the Choohoo system will randomly select 1  (one) or more participants to receive the Money-Reward. Where more than 1 (one) participant has been selected, the value of that Money-Reward will be split equally between the selected participants/selected recipients.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.8</div>
                        <div className="w-11/12 pl-16">All selected recipients will be notified on the Platform (i.e. in-App notification and by email - using the email address registered on the selected recipient’s user profile) within 1 (one) minute of the closing time of the SFT Pod, of their selection and reward. Should we not be able to reach a selected recipient (for instance, they do not respond) after our first notification, we will continue trying to reach that selected recipient on a weekly basis (via In-App notification and his/her registered email address) for a period of 12 (twelve) calendar months to inform them of their selection and reward so that they may follow the claim and verification process on the Platform. Should we remain unsuccessful in reaching a selected recipient by the end of the 12 (twelve) calendar month period, such selected recipient will be deemed to have renounced their reward and clause 13.2.18 below will apply.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.9</div>
                        <div className="w-11/12 pl-16">In the unlikely event that ALL selected recipients of a particular MRO cannot be reached for 12 (twelve) calendar months from the first notification of their selection, the MRO will be voided and clause 13.2.18 below will apply.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.10</div>
                        <div className="w-11/12 pl-16">The selected recipients that we successfully reach and inform of their reward will then be required to furnish their Identity (ID) book/Smart Card, bank account details and a photo of themselves by following the claim and verification process on the Platform. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.11</div>
                        <div className="w-11/12 pl-16">The rewards will be paid into the selected recipients’ bank accounts within 2 (two) calendar weeks of successful completion of the claim and verification process on the Platform.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]"></div>
                        <div className="w-11/12 pl-4"><b className='font-semibold'>General</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.12</div>
                        <div className="w-11/12 pl-16">These MRO terms and conditions are governed by the CPA, which will apply to the MRO and all information relating to the MRO (including the promotional and advertising material published).</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.13</div>
                        <div className="w-11/12 pl-16">Choohoo is the promoter of the MRO. Any reference to we/us/our includes our directors, members, partners, sponsors, employees, agents, consultants, suppliers, contractors and, where the context allows for it.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.14</div>
                        <div className="w-11/12 pl-16">No entry will be considered valid until a validation process has taken place.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.15</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>The MRO terms and conditions may be amended by Choohoo at any time, with or without notice. </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.16</div>
                        <div className="w-11/12 pl-16">By participating in the MRO, you agree to be bound by the MRO terms and conditions and these Platform Terms of Use which it forms a part of; </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.17</div>
                        <div className="w-11/12 pl-16">You are NOT eligible to participate in an MRO if you are a director, member, partner, employee, agent or consultant of Choohoo; </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.18</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>If any selected recipient informs us that he/she does not wish to receive their reward for any reason whatsoever, if an entry is found not valid for any reason whatsoever, if a selected recipient has breached the MRO terms and conditions, if a selected recipient renounce(s) their reward, or if we deem such selected recipient to have renounced their reward (for example as per clause 13.1.8 above), or the MRO is regarded as voided (for example as per clause 13.2.9 above), we reserve the right to declare the reward forfeited.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.19</div>
                        <div className="w-11/12 pl-16">The reward may not be exchanged for or substituted by any other item. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.20</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>All selected recipients who accept the reward do so entirely at their own risk. </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.21</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>We will need to collect and process your personal information to validate your entry and if you are a selected recipient, to make the reward available to you. By entering the MRO, you consent to us processing your personal information for this purpose. </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.22</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>We will be entitled to publish each reward recipient’s name and photograph in any advertising, promotional, print, point of sale or public relations material (the nature and manner of such releases to be determined within our sole discretion). Each recipient will however be given the opportunity to decline the publication of their photographs and to participate in our marketing material insofar as it relates to the MRO. </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.23</div>
                        <div className="w-11/12 pl-16">In the event of a dispute, our decision will be final and binding on all aspects of the MRO and no correspondence will be entered into.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.24</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>We assume no risk and/or liability whatsoever for the failure of any technical element in the MRO which may result in an entry not being successfully submitted and/or selected recipient/s not successfully receiving their reward.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.25</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>We assume no liability whatsoever for any indirect loss, harm or damage arising from a user’s participation in the MRO howsoever arising. All users, whilst participating in the MRO, indemnify and hold us harmless for any loss, damage, harm, or injury (whether arising from negligence or otherwise) which may be sustained because of any claim, cost, expense, loss or damage which may be made or suffered by a user or any third party. </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">13.2.26</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>We will have the right to terminate this MRO with immediate effect and without notice of such termination for any reason whatsoever. In such event, all users/participants hereby waive any rights, which they may have against us and hereby acknowledge that they will have no recourse or claim of any nature against us.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">14.</div>
                        <div className="w-11/12"><b className='font-semibold'>Privacy Policy</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]"></div>
                        <div className="w-11/12 pl-8">We respect your privacy and will take all reasonable measures to protect it, as more fully detailed in our Privacy Policy, which is incorporated by reference.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">15.</div>
                        <div className="w-11/12"><b className='font-semibold'>Electronic communications</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">15.1</div>
                        <div className="w-11/12 pl-8">When you visit or use the Platform or send emails to us, you consent to receiving communications from us or any of our divisions, affiliates or partners electronically in accordance with our Privacy Policy as set out in clause 14 above.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">15.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Please note however that email communications are an integral part of our Platform operations and that should you choose to unsubscribe from our email communications, you will be opting out of the entire Choohoo service and any further Choohoo electronic communications including but not limited to email, social media etc.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">16.</div>
                        <div className="w-11/12"><b className='font-semibold'>Tax–benefit certificates</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]"></div>
                        <div className="w-11/12 pl-8">Given that a portion of the purchase price that users pay for SFTs is donated towards public benefit/social cause programmes as selected by Choohoo, the Platform partner (an organisation with public benefit organisation status, registered with the tax exemption unit at the South African Revenue Services) will issue such users with a tax-benefit section 18A certificate or section18A donation receipt (to the value of the donation portion per SFT), which will allow the user (donor) a tax deduction as determined by the South African Revenue Services. In the event that the purchase takes place outside of South Africa, the relevant country’s tax authority and legislation will apply. Tax-benefit certificates will be issued via in-App notification and email.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.</div>
                        <div className="w-11/12"><b className='font-semibold'>Ownership and intellectual property rights</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.1</div>
                        <div className="w-11/12 pl-8">The contents of the Platform, including any material, information, data, software, icons, text,  graphics, lay-outs, images, sound clips, advertisements, video clips, trade names, logos, trade-marks, designs and service marks which are displayed on or incorporated in the Platform (“<b className='font-semibold'>Platform Content</b>”) are protected by law, including but not limited to copyright and trade mark law. The Platform Content is the property of Choohoo, its partners, advertisers and/or sponsors/ and/or is licensed to Choohoo. </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>It is important to note that each SFT for sale on the Platform is a unique copy of an original creative work. Purchasing of any SFTs on the Platform does not include acquisition of the intellectual property rights in the original creative work. The intellectual property rights in the creative work remain vested in the creator/owner thereof.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.3</div>
                        <div className="w-11/12 pl-8">Any use, distribution or reproduction of the Platform Content is prohibited unless expressly authorised in these Terms or otherwise provided for in law. To request permission for the commercial use of any Platform Content, contact us at <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a>.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.4</div>
                        <div className="w-11/12 pl-8">Where any of the Platform Content has been licensed to Choohoo or belongs to any third party, your rights of use will also be subject to their ownership and intellectual property rights and (if applicable) any terms and conditions which that licensor or third party imposes from time to time and you agree to comply with such third party terms and conditions.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.5</div>
                        <div className="w-11/12 pl-8">You will not acquire any right, title or interest in or to the Platform.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.6</div>
                        <div className="w-11/12 pl-8">You may not copy, adapt, modify, alter, decompile, reverse engineer, attempt to derive the source code of or create derivative works or, or otherwise attempt to reproduce the Platform, its design, any updates to the Platform  and/or any proprietary features in relation to it, or any parts of it.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.7</div>
                        <div className="w-11/12 pl-8">You may not establish a coded frame to the Platform or any part thereof, including the website app or mobile app.</div>
                    </div>
                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">17.8</div>
                        <div className="w-11/12 pl-8">You indemnify Choohoo from and against all actions, claims, costs, demands, expenses and other liabilities suffered or incurred by Choohoo as a result of any third-party claims initiated or instituted against us relating to your unauthorised use of the Platform Content, Platform and any intellectual property rights flowing from same.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.</div>
                        <div className="w-11/12"><b className='font-semibold'>Disclaimer</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.1</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>The use of the Platform is entirely at your own risk and you assume full responsibility for any risk or loss resulting from use of the Platform or reliance on any information on the Platform.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.2</div>
                        <div className="w-11/12 pl-8">Whilst Choohoo takes reasonable measures to ensure that the contents of the Platform are accurate and complete, Choohoo makes no representations or warranties, whether express or implied, as to the quality, timeliness, operation, integrity, availability or functionality of the Platform or as to the accuracy, completeness or reliability of any information on the Platform and on any other official communications from Choohoo. Choohoo shall not be bound by  any such representations or warranties made by Choohoo’s representatives.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo disclaims liability for any damage, loss or expenses, whether direct, indirect or consequential in nature, arising out of or in connection with your access to or use of the Platform and/or any content thereon unless otherwise provided by law.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.4</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>The Platform itself and all information provided on the Platform is provided “as is” without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, completeness, or non-infringement, as may be allowed in law.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.5</div>
                        <div className="w-11/12 pl-8">Any views or statements made or expressed on the Platform are not necessarily the views of Choohoo, its directors, members, employees and/or agents.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">18.5</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>In addition to the disclaimers contained elsewhere in these Terms, Choohoo makes no warranty or representation, whether express or implied, that the information or files available on the Platform are free of viruses, spyware, malware, trojans, destructive materials or any other data or code which is able to corrupt, destroy, compromise, disrupt, disable, harm, jeopardise or otherwise impede in any manner the operation, stability, security, functionality or content of your technological device(s), computer system, computer network, hardware or software in any way. You accept all risk associated with the existence of such viruses, destructive materials or any other data or code which is able to corrupt, compromise, jeopardise, disrupt, disable, harm or otherwise impede in any manner the operation or content of a computer system, computer network, any handset or mobile device, or your hardware or software, save where such risks arise due to the gross negligence or wilful misconduct of Choohoo, its employees, agents or authorised representatives. Choohoo thus disclaims all liability for any damage, loss or liability of any nature whatsoever arising out of or in in connection with your access to or use of the Platform.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">19.</div>
                        <div className="w-11/12"><b className='font-semibold'>Linking to Third Party Websites</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">19.1</div>
                        <div className="w-11/12 pl-8">The Platform may contain links or references to other websites (“Third Party Websites”) which are outside of our control, including those of advertisers. These Terms do not apply to those Third Party Websites and Choohoo is not responsible for the practices and/or privacy policies of those Third Party Websites or the “cookies” that those sites may use.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">19.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Notwithstanding the fact that the Platform may refer to or provide links to Third Party Websites, your use of such Third Party Websites is entirely at your own risk and we are not responsible for any loss, expense, claim or damage, whether direct, indirect or consequential, arising from your use of such Third Party Websites or your reliance on any information contained therein.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">20.</div>
                        <div className="w-11/12"><b className='font-semibold'>Limitation of liability</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">20.1</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo cannot be held liable for any inaccurate information published on the Platform and/or any incorrect prices displayed on the Platform, save where such liability arises from the gross negligence or wilful misconduct of Choohoo, its employees, agents or authorised representatives. You are encouraged to contact us to report any possible malfunctions or errors by way of our Support Services at <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a></b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">20.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo shall not be liable for any direct, indirect, incidental, special or consequential loss or damages which might arise from your use of, or reliance upon, the Platform or the content contained on the Platform; or your inability to use the Platform, and/or unlawful activity on the Platform and/or any linked Third Party Website.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">20.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>20.3	You hereby indemnify Choohoo against any loss, claim or damage which may be suffered by yourself or any third party arising in any way from your use of the Platform and/or any linked Third Party Website.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.</div>
                        <div className="w-11/12"><b className='font-semibold'>Availability and termination</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.1</div>
                        <div className="w-11/12 pl-8">We will use reasonable endeavours to maintain the availability of the Platform, except during scheduled maintenance periods, and are entitled to discontinue providing the Platform or any part thereof with or without notice to you.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.2</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>Choohoo may in its sole discretion terminate, suspend and modify the Platform, with or without notice to you. You agree that Choohoo will not be liable to you in the event that it chooses to suspend, modify or terminate the Platform other than for processing any orders made by you prior to such time, to the extent possible.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.3</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>21.3	 If you fail to comply with your obligations under these Terms, including any incident involving payment of the price of any SFTs, this may (in our sole discretion with or without notice to you) lead to a suspension, termination and/or banning of your access to the Platform without any prejudice to any claims for damages or otherwise that we may have against you.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.4</div>
                        <div className="w-11/12 pl-8"><b className='font-semibold'>21.4	Choohoo is entitled, for purposes of preventing suspected fraud and/or where it suspects that you are abusing the Platform and/or have created multiple user profiles to take advantage of a promotion or Code intended by Choohoo to be used once-off by you, to ban you from its database (including suspending or terminating your access to the Platform, refusing to accept or process payment on any order, and/or to cancel any order made by you, in whole or in part, on notice to you). Choohoo shall only be liable to refund monies already paid by you (see Choohoo’s Returns Policy in this regard), and accepts no other liability which may arise as a result of such banning and/or refusal to process any order/purchase.</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">21.5</div>
                        <div className="w-11/12 pl-8">21.5	At any time, you can choose to stop using the Platform, with or without notice to Choohoo.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">22.</div>
                        <div className="w-11/12"><b className='font-semibold'>Governing law and jurisdiction </b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">22.1</div>
                        <div className="w-11/12 pl-8">These Terms and our relationship and/or any dispute arising from or in connection with these Terms shall be governed and interpreted in accordance with the laws of the Republic of South Africa. Your continued use of the Platform will constitute your consent and submission to the jurisdiction of the South African courts regarding all proceedings, transactions, applications or the like instituted by either party against the other, arising from any of these Terms.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">22.2</div>
                        <div className="w-11/12 pl-8">In the event of any dispute arising between you and Choohoo, you hereby consent to the non-exclusive jurisdiction of the High Court of the Republic of South Africa (Western Cape Division, Cape Town) notwithstanding that the quantum in the action or proceedings may otherwise fall below the monetary jurisdiction of that court.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">22.3</div>
                        <div className="w-11/12 pl-8">Nothing in this clause 22 or the Terms limits your right to approach any court, tribunal or forum of competent jurisdiction in terms of the CPA.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.</div>
                        <div className="w-11/12"><b className='font-semibold'>Notices</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.1</div>
                        <div className="w-11/12 pl-8">The physical  address below is for the service of all formal notices and legal processes in connection with these Terms and Conditions, on Choohoo (“legal address”): 105 1st Avenue, 503 Greyville, Harfield Village, 7708, Claremont, Cape Town, South Africa
                            <br />Choohoo may change this address from time to time by updating these Terms.
                        </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.2</div>
                        <div className="w-11/12 pl-8">Notices must be sent either by hand, prepaid registered post or email and must be in English. All notices sent -</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.2.1</div>
                        <div className="w-11/12 pl-16">by hand will be deemed to have been received on the date of delivery;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.2.2</div>
                        <div className="w-11/12 pl-16">by prepaid registered post, will be deemed to have been received 10 (ten) days after the date of posting;</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">23.2.2</div>
                        <div className="w-11/12 pl-16">by email will be deemed to have been on the date indicated in the “Read Receipt” notification. <b className='font-semibold'>All email communications between you and us must make use of the “read receipt” function to serve as proof that an email has been received.</b></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">24.</div>
                        <div className="w-11/12"><b className='font-semibold'>Complaints</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">24.1</div>
                        <div className="w-11/12 pl-8">If you have a complaint about the goods or services provided by us or require information regarding our internal complaints-handling process, please get in touch with our Support Services on <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a>.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">24.2</div>
                        <div className="w-11/12 pl-8">If we are unable to resolve your complaint to your satisfaction or we cannot resolve your complaint within 15 (fifteen) business days of you having notified us of it, you can approach the Consumer Goods and Services Ombud (“CGSO”) to assist in resolving the complaint. The CGSO’s contact details are: website: <a href='http://www.cgso.org.za' className='text-blue-600' target='blank'>http://www.cgso.org.za</a>; sharecall: 0860 000 27; email: <a href='mailto:complaints@cgso.org.za' className='text-blue-600' target='blank'>complaints@cgso.org.za</a>.</div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.</div>
                        <div className="w-11/12"><b className='font-semibold'>Information</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1</div>
                        <div className="w-11/12 pl-8">For the purposes of the Electronic Communications and Transactions Act 25 of 2002, as amended, Choohoo’s information is as follows, which should be read in  conjunction with its product and service descriptions and other terms and conditions contained on the Platform:</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.1</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Full name</b>: Choohoo (Proprietary) Limited, a private company registered in South Africa with registration number  2020/925363/07.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.2</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Main business</b>: Online shared marketplace for digital content (images, video, audio, written)</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.3</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Physical address for receipt of legal service </b>: 105 1st Avenue, 503 Greyville, Harfield Village, 7708, Claremont, Cape Town, South Africa
                            <br />- (marked for attention: CEO and Legal)
                        </div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.4</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Postal address for all other correspondence</b>: PO BOX 2098, CLAREINCH, 7740, Cape Town, South Africa</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.5</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Office bearers</b>: Pashkar Moodliar, Daoud Saunders</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.6</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Phone number</b>: +27 021 683 6117</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.7</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Email address</b>: <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.8</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>Privacy Policy</b>: may be found below</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">25.1.9</div>
                        <div className="w-11/12 pl-16"><b className='font-semibold'>PAIA</b>: The manual published in terms of section 51 of the Promotion of Access to Information Act 2000 may be downloaded <a href='https://www.choohoo.world/document/Choohoo_PAIA_Manual.pdf' className='text-blue-600' target='blank'><u>here</u></a></div>
                    </div>

                    <div className="mt-8 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.</div>
                        <div className="w-11/12"><b className='font-semibold'>General</b></div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1</div>
                        <div className="w-11/12 pl-8">Choohoo may, in its sole discretion, at any time and for any reason and without prior written notice, suspend or terminate the operation of the Platform or the user’s right to use the Platform or any of its contents subject to us processing any orders then already made by you.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.1</div>
                        <div className="w-11/12 pl-16">You may not cede, assign or otherwise transfer your rights and obligations in terms of these Terms to any third party.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.2</div>
                        <div className="w-11/12 pl-16">Any failure on the part of you or Choohoo to enforce any right in terms hereof shall not constitute a waiver of that right.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.3</div>
                        <div className="w-11/12 pl-16">If any term or condition contained herein is declared invalid, the remaining terms and conditions will remain in full force and effect.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.4</div>
                        <div className="w-11/12 pl-16">No variation, addition, deletion, or agreed cancellation of these Terms will be of any force or effect unless in writing and accepted by or on behalf of the parties hereto.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.5</div>
                        <div className="w-11/12 pl-16">No indulgence, extension of time, relaxation or latitude which any party (the “grantor”) may show grant or allow to the other (the “grantee”) shall constitute a waiver by the grantor of any of the grantor’s rights and the grantor shall not thereby be prejudiced or stopped from exercising any of its rights against the grantee which may have arisen in the past or which might arise in the future.</div>
                    </div>
                    <div className="mt-1 text-[18px] md:text-[20px] font-light w-full flex">
                        <div className="w-[10%] md:w-[5%]">26.1.6</div>
                        <div className="w-11/12 pl-16">These Terms contain the whole agreement between you and Choohoo and no other warranty or undertaking is valid, unless contained in this document between the parties.</div>
                    </div>

                </div>
            </div>

            <hr className='mt-11 md:w-2/4 w-4/5 m-auto' />

            <div className='w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto'>
                <div className='mt-16'>
                    <p className='font-extrabold text-[2rem]'>
                        CHOOHOO PRIVACY POLICY
                    </p>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        Version 1.01 Published date: 2 October 2022
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        This Privacy Policy applies to all users including registered users of our website or mobile application (“Choohoo Platforms” or “Platforms”).
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We respect your privacy and take the protection of your personal information and the compliance with all applicable data privacy/protection laws very seriously.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The purpose of this notice is to explain how, why and when Choohoo (Pty) Ltd uses your personal information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        By using our Platforms, you agree to the processing of your personal information as set out in this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>COLLECTION OF PERSONAL INFORMATION</p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We process your information only in a manner which is relevant, reasonable and purpose-specific.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We collect, process and store information about you such as:
                        <ul>
                            <li className='list-disc text-[22px] md:text-[24px] font-normal ml-12 mt-4'>
                                Personal information you provide us with, including:
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        your name and surname, email address, mobile number, physical address, geo-location, payment method, online identifiers for example your social media handles, gender, identification number.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        We collect this information in numerous ways such as when you register /sign-up for an account, purchase an SFT, or apply for available opportunities like creative work partnerships, public benefit partnership or sales and marketing partnerships on our Platforms.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        Personal information of minors: you must be at least 14 years of age to use any of our Platforms, products and services and you must have the consent of your parent or legal guardian. Minors (i.e. persons under 18 years of age) must have the consent of the parent or legal guardian to give us their Personal Information. Therefore, if you are under the age of 18, please ensure that you have obtained your parent or legal guardian’s consent before using our Platforms or putting any of your information on our Platforms.  If we find out that you do not have this consent, we can block/suspend your account on the Platform.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Should your personal information change or you wish to correct any error or make any amendment to your submitted personal information you can do this by updating your registered user information in your account profile.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal italic'>
                        You warrant that the information you provide is accurate, true and correct and that it does not impersonate or misrepresent any person or entity or falsely state or otherwise misrepresent you or any affiliations that you may have.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-normal  mt-4'>
                            Information received automatically:
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Whether or not you are a registered user, depending on how you access and use our Platforms, we may receive information automatically while you are using our Platforms such as:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            log information through online identifiers on how, when and for how long you use our Platforms
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            information about the device you use to access or use our Platforms, including the type of device,
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            your browser or operating system and your Internet Protocol address,
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            what you view and the search queries you submit;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            your interactions with our communications such as correspondence/messages, advertisements and push notifications;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the geographic location from which you access our Platforms, including your device’s global positioning system signal and information about nearby wifi networks and cell towers while you are using our Platforms;
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>WHY WE PROCESS YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We process your personal information to identify you, verify your identity, and (if applicable) create a user account for you on our Platforms or enter into a contract with you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are a registered user, we will also process your information to:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            meet our contractual obligations to you.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            provide you with any relevant information you may request from us.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            communicate with you regarding billing, customer support, resolving complaints and quality control.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            inform you about changes to our Platforms, products and services, terms and conditions, Privacy Policy or notices.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            subject to and in accordance with our Platform terms and conditions including promotional competition terms and conditions published on the Platform/s from time to time, and provided you have purchased an SFT/or received a relevant code, enter your relevant details into our in-app Money-Reward lucky draw.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            send you information about other in-app promotional competitions, products or services that may interest you regarding our Platforms (unless you have opted out of direct marketing communications).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            get feedback from you in order to improve our Platforms and existing products and services, develop new products and services and grow our business.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            comply with any legal or regulatory obligations such as tax, financial laws etc.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            carry out statistical and other research for instance to identify potential markets and trends. This process will however anonymise/de-identify any personal information and cannot be linked to you.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>RETENTION AND RESTRICTION OF RECORDS</p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We keep your personal information so that we may continue providing our Platforms, products services to you, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Policy; as may be required by law (including for tax and accounting purposes); for as long as it may be necessary uphold the contract between you and us. How long we retain specific Personal Information varies depending on the purpose for its use, our internal retention policies. We will delete information in accordance with applicable law.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>USER TRACKING AND COOKIES</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We use technological tools to improve your experience on our Platforms through the use of cookies and/or user tracking. A cookie is a small text file that is stored on your device when you visit a website or use an app. The files contain specific information related to your use of our Platforms, such as your login details, preference settings and tracking identifiers
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Cookies assist us in personalising your interactions with our Platforms, tailor our services to your interests and needs, and ensure they work on your device, provide advertising that is relevant to you, limit the number of times you see the same advertisement and measure the effectiveness of the advertisements on our Platforms, based on your online activities on other websites, or in relation to the Platforms or content you view on the Platforms, and information we have about you.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>By accessing and using the Platform, you consent to our use of cookies.</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We would from time to time like to share information with you about our Platforms, own products and services or about promotions on similar products and services used by you, using your indicated preferred method of communication such as email, text message, mobile phone etc.  If you have opted in to receive marketing communications, you may always opt out by clicking on the “unsubscribe” or “opt out” option included in our marketing communications. You may also contact us on <a href='mailto:support@choohoo.world' className='text-blue-600' target='blank'>support@choohoo.world</a> for us to note that you have indicated “No” to receiving direct marketing material.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>We do not sell your information to third parties for any purpose whatsoever..</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may appoint individuals or companies to assist us in carrying out certain functions for the operation and provision of our Platforms, products and services provided through our Platforms, such as:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            the marketing of SFT’s
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            sending communications
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            processing payments
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            assessing compliance risks
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            analysing data
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            conducting customer relationship management
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            conducting training
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        These third party service providers have access to personal information needed to perform their functions, but may not use it for any other purposes and must treat it as confidential. Further, they must process that information in accordance with this Privacy Policy and in accordance with applicable data protection laws.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may further provide or make information available to:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            our employees, and/or third party service providers in order to enable them to assist us to interact with you via our Platforms for the marketing, sales, digital delivery of SFT’s, or operational reasons or regarding promotions, (excluding direct marketing if you have opted out of same).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            law enforcement, government officials, fraud detection agencies or other third parties when the disclosure of personal information is necessary or appropriate in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us to legal liability or financial loss, to report or support the investigation into suspected illegal activity.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            third parties (such as a potential purchaser and its professional advisors) in the event of any reorganisation, merger, consolation, sale, joint venture, or other disposition of any or all of our assets.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            service providers contracted who to assist with parts of our business operations (such as fraud prevention, public relations etc.) However, our contracts dictate that these service providers may only use your information in connection with the services they perform for us and not for their own benefit or any other purpose, and must treat such information as confidential.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            any suppliers in order for them to liaise directly with you regarding any products or services made available through our Platforms, or for any other purpose which may require their involvement for purposes of the Platforms.
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>At your Option</u>: other than as set out in this Privacy Policy, you will receive notice when Personal Information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        <u>If you are located in the European Union (EU) or the United Kingdom (UK), please note:</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The General Data Protection Regulation (GDPR) and UK GDPR requires us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Consent.</u>  We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. (For instance, see ‘HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING’ above).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Performance of a Contract.</u> We process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Platforms, products or services, related partnership opportunities made available through our Platforms (creative partnerships, sales and marketing partnerships or at your request prior to entering into a contract with
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Legitimate Interests.</u> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            send you information about special offers and on our products and services (unless you have unsubscribed/opted out of direct marketing).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            develop and then display (in-app) personalised and relevant advertising content.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            analyse how our services are used so we can improve them to engage and retain users.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            support our marketing activities.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            diagnose problems and/or prevent fraudulent activities.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            understand how our users use our products and services so we can improve user experience.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            marketing
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Legal Obligations.</u> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Vital Interests.</u> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        <u>If you are located in Canada, please note:</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. (For instance, see ‘HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING’ above’).
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In some exceptional cases, we may be legally permitted under applicable law to  process your information without your consent, including, for example:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For investigations and fraud detection and prevention.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For business transactions provided certain conditions are met.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For identifying injured, ill, or deceased persons and communicating with next of kin.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If the collection is solely for journalistic, artistic, or literary purposes.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If the information is publicly available and is specified by the regulations.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>IS YOUR PERSONAL INFORMATION TRANSFERRED INTERNATIONALLY?</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may transfer, store, and process your personal information in countries other than your own. We do this for the purposes set out above (see ‘WHY WE PROCESS YOUR PERSONAL INFORMATION’ above), including for data storage and back-up purposes to ensure the integrity of our systems.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        When we transfer/store/process your Personal Information cross border/internationally, we will ensure that we do so in accordance with the requirements for lawful transfer as set out in applicable data privacy/protection laws.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Our servers are located in South Africa. If you are accessing our Services from outside South Africa, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see ‘WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION’ above), in South Africa, United States, Germany, Dubai, Australia, Brazil, United Kingdom, Spain, France, Italy, Austria, Belgium, Bulgaria, Finland, Ireland, Greece, Hungary, Cyprus, Czech Republic, Latvia, Luxembourg, Denmark, Estonia, Malta, Netherlands, Portugal, Romania, Slovakia, Slovenia, Sweden, and other countries.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are a resident in the European Economic Area (‘EEA’) or the UK, then these countries may not necessarily have data protection/privacy laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this Privacy Policy and applicable laws.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The European Commission’s Standard Contractual Clauses:
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third- party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        By accessing and using our Platforms, you consent to us transferring, storing and processing your Personal Information internationally in accordance with this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>HOW WE SECURE YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We secure the integrity and confidentiality of your personal information in our possession or under our control by taking appropriate, reasonable technical and organisational measures to prevent loss of, damage to or unauthorised destruction of personal information and unlawful access to or processing of personal information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In order to implement and maintain such measures, we have policies, controls and related processes, which are reviewed regularly. These include:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            physical, technical and network security.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            access controls and monitoring of access.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            secure storage, destruction and encryption of records of Personal Information.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            personal information breach reporting and remediation.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            written security and confidentiality obligations placed on third parties (based within or outside the borders of South Africa) who process personal information as part of rendering services to us.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal italic'>
                        Should you disclose your Personal Information to any third party other than us, we shall not be liable for any loss or damage arising or suffered by you as a result of the disclosure of such Information to any third party. This is because we do not regulate or control how that third party uses your personal information. You should always ensure that you read the privacy policy of any third party.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>YOU HAVE THE RIGHT TO:</p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            view, correct or amend your personal information. Registered users can do this through their user account for the personal information reflected therein.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            request a record or description of your personal information.  We may charge a fee in order to provide you with this record. Where requests to access and amend your personal information are self-evidently unfounded, excessive/repetitive we may charge an additional administrative fee or refuse the request.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are a resident of California, you are granted specific rights regarding access to your personal information:
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        The California Civil Code Section 1798.83, also known as the ‘Shine The Light’ law, permits users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            request to have your personal information corrected, destroyed or deleted. <b>You can stop being a registered user by cancelling your account. In this instance we will only retain your Personal Information subject to any legislative requirement and/or our internal retention policy.</b>
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            us complying with your requests upon receipt unless we have credible reason why we cannot comply.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            us indicating where, if we cannot agree whether to correct or delete your personal information as requested, a correction or deletion was requested but not made.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            inform you if reasonably practicable,  should we change your personal information and this has an impact on decisions about you.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            notify you of the action taken by us because of your request.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            notify you of unauthorised access to your Personal Information.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            provide you with reasonable evidence of our compliance with our obligations under this Privacy Policy on reasonable notice and written request.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            If you located in South Africa and you believe we are unlawfully processing your personal information, you may submit a complaint to the Information Regulator whose contact details can be found on its website at: <a href='https://www.inforegulator.org.za' target='_blank' className='text-blue-600'>www.inforegulator.org.za</a>
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
                        </li>
                    </ul>
                    <p className='text-[22px] md:text-[24px] font-thin overflow-hidden ml-12'>
                        <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm' target='_blank' className='text-blue-600'>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            If you are located in Switzerland, the contact details for the data protection authorities are available here:
                        </li>
                    </ul>
                    <p className='text-[22px] md:text-[24px] font-thin overflow-hidden ml-12'>
                        <a href='https://www.edoeb.admin.ch/edoeb/en/home.html' target='_blank' className='text-blue-600'>https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            you can exercise all your rights as set out above by referring to our process set out in our PAIA Manual, <a href='https://www.choohoo.world/document/Choohoo_PAIA_Manual.pdf' target='_blank' className='text-blue-600'>here</a>. (‘PAIA’ refers to the South African statute – Promotion of Access to Information Act 2 of 2000).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you have questions or comments about this Privacy Policy, you may contact our support services by email at support@choohoo.world or by post to PO Box 2098 Clareinch 7740, Cape Town South Africa.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>CALIFORNIA RESIDENTS’ SPECIFIC PRIVACY RIGHTS</p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are a resident of California, you are granted specific rights regarding access to your personal information:
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            The California Civil Code Section 1798.83, also known as the ‘Shine The Light’ law, permits users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing addressing it to our support services at support@choohoo.world.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-normal ml-12 mt-12'>
                            <b className='font-bold'>The California Consumer Privacy Act (CCPA) Privacy Notice</b>
                        </li>
                    </ul>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The California Code of Regulations defines a ‘resident’ as:
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        (1) every individual who is in the State of California for other than a temporary or transitory   purpose and
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        All other individuals are defined as ‘non-residents’. If this definition of ‘resident’ applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    </p>
                    <p className='text-[18px] md:text-[22px] font-normal mt-4'>
                        What categories of personal information do we collect?
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We have collected the following categories of personal information in the past twelve 12 months:
                    </p>
                </div>

                {/* Table Content */}

                <div className='mt-11 ml-12'>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-normal w-1/5'>Category</th>
                        <th className='text-[18px] md:text-[22px] font-normal'>Examples</th>
                        <th className='text-[18px] md:text-[22px] font-normal w-1/24'>Collected</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>A. Identifiers</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>B. Personal information categories listed in the California Customer <br /><br />Records statute</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Name, contact information, education, employment, employment history, and financial information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>C. Protected classification <br /><br />characteristics under California or federal law</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Gender and date of birth</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>D. Commercial Information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Transaction information, purchase history, financial details, and payment information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>E. Internet and other similar network activity</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>F. Geolocation data</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Device location</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>G. Audio, electronic, visual or similar information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Images and audio, video  created in connection with our business activities</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>H. Professional or employment-related information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>I. Education Information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Student records and directory information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>J. Inferences drawn from other personal information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and Characteristics</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                </div>


                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may also collect other personal information outside of these categories, instances where you interact with us in person, online, or by phone or mail in the context of:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            Receiving help through our customer support channels;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Participation in customer surveys or contests; and
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Facilitation in the delivery of our products and services and to respond to your inquiries.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>How do we use and share your personal information?</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Choohoo Proprietary Limited collects and shares your personal information through:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Targeting cookies/Marketing cookies
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        More information about our data collection and sharing practices can be found in this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may contact us by email at <a href='mailto:support@choohoo.world' target='_blank' className='text-blue-600'>support@choohoo.world</a> or by referring to the contact details the bottom of this document.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are using an authorised agent to exercise your right to opt out we may deny a   request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-normal mt-2'>
                        Will your information be shared with anyone else?
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is either a for- profit entity or a non-profit entity that processes the information on our behalf.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be ‘selling’ of your personal information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Choohoo Proprietary Limited has not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Choohoo Proprietary Limited has not disclosed any of the above tabulated categories of personal information to third parties for business or commercial purposes in the preceding twelve (12) months.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The categories of third parties to whom we may disclose personal information for a business or commercial purposes can be found under ‘WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION’ above.
                    </p>
                    <p className='text-[18px] md:text-[22px] font-normal mt-2'>
                        Your rights with respect to your personal data
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Right to request deletion of the data — Request to delete</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Right to be informed — Request to know</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Depending on the circumstances, you have a right to know:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            whether we collect and use your personal information;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of personal information that we collect;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the purposes for which the collected personal information is used;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            whether we sell your personal information to third parties;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of personal information that we disclosed for a business purpose;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of third parties to whom the personal information was disclosed for a business purpose; and
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the business or commercial purpose for collecting personal
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re- identify individual data to verify a consumer request.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy</u>
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-12'>
                        <u>Rights</u>
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-12'>
                        We will not discriminate against you if you exercise your privacy rights.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        <u>Verification process</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Other privacy rights</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may object to the processing of your personal information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        To exercise these rights, you can contact us by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        UPDATING THIS PRIVACY POLICY
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may update this Privacy Policy from time to time. When you use our Platforms the version of the Privacy Policy posted on this page applies to you. The updated version will be indicated by an updated ‘Revised’ date. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[2.5rem] font-extrabold'>
                        HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        If you have questions or comments about this policy, you may contact our support services by email at <a href='mailto:support@choohoo.world' target='_blank' className='text-blue-600'>support@choohoo.world</a> or by post to: Choohoo Proprietary Limited, PO Box 2098 Clareinch, 7740 Cape Town South Africa.
                    </p>
                </div>

                <div className='mt-16 mb-8 text-[14px] text-center font-light'>
                    <p>Copyright © CHOOHOO (PTY) LTD 2022. All Rights Reserved. Cape Town, South Africa.</p>
                </div>

            </div>
        </div>
    )
}
export default Terms;

