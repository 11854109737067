import React from 'react'
import { Helmet } from 'react-helmet-async';
import logo from '../assets/logo.png'

function PrivacyPolicy() {
    return (
        <div>
            <Helmet>
                <title>Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits.</title>
                <meta name="description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
                <meta property="og:title" content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits." />
                <meta property="og:description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
            </Helmet>
            <div className='flex content-center justify-center'>
                <a href='/'>
                    <img src={logo} alt='logo' className='w-52 mt-10' />
                </a>
            </div>

            <div className='w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto'>
                <div className='mt-16'>
                    <p className='font-extrabold text-[2rem]'>
                        CHOOHOO PRIVACY POLICY
                    </p>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        Version 1.01 Published date: 2 October 2022
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        This Privacy Policy applies to all users including registered users of our website or mobile application (“Choohoo Platforms” or “Platforms”).
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We respect your privacy and take the protection of your personal information and the compliance with all applicable data privacy/protection laws very seriously.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The purpose of this notice is to explain how, why and when Choohoo (Pty) Ltd uses your personal information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        By using our Platforms, you agree to the processing of your personal information as set out in this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>COLLECTION OF PERSONAL INFORMATION</p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We process your information only in a manner which is relevant, reasonable and purpose-specific.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We collect, process and store information about you such as:
                        <ul>
                            <li className='list-disc text-[22px] md:text-[24px] font-normal ml-12 mt-4'>
                                Personal information you provide us with, including:
                            </li>
                        </ul>
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        your name and surname, email address, mobile number, physical address, geo-location, payment method, online identifiers for example your social media handles, gender, identification number.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        We collect this information in numerous ways such as when you register /sign-up for an account, purchase an SFT, or apply for available opportunities like creative work partnerships, public benefit partnership or sales and marketing partnerships on our Platforms.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        Personal information of minors: you must be at least 14 years of age to use any of our Platforms, products and services and you must have the consent of your parent or legal guardian. Minors (i.e. persons under 18 years of age) must have the consent of the parent or legal guardian to give us their Personal Information. Therefore, if you are under the age of 18, please ensure that you have obtained your parent or legal guardian’s consent before using our Platforms or putting any of your information on our Platforms.  If we find out that you do not have this consent, we can block/suspend your account on the Platform.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Should your personal information change or you wish to correct any error or make any amendment to your submitted personal information you can do this by updating your registered user information in your account profile.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal italic'>
                        You warrant that the information you provide is accurate, true and correct and that it does not impersonate or misrepresent any person or entity or falsely state or otherwise misrepresent you or any affiliations that you may have.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-normal  mt-4'>
                            Information received automatically:
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Whether or not you are a registered user, depending on how you access and use our Platforms, we may receive information automatically while you are using our Platforms such as:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            log information through online identifiers on how, when and for how long you use our Platforms
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            information about the device you use to access or use our Platforms, including the type of device,
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            your browser or operating system and your Internet Protocol address,
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            what you view and the search queries you submit;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            your interactions with our communications such as correspondence/messages, advertisements and push notifications;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the geographic location from which you access our Platforms, including your device’s global positioning system signal and information about nearby wifi networks and cell towers while you are using our Platforms;
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>WHY WE PROCESS YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We process your personal information to identify you, verify your identity, and (if applicable) create a user account for you on our Platforms or enter into a contract with you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are a registered user, we will also process your information to:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            meet our contractual obligations to you.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            provide you with any relevant information you may request from us.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            communicate with you regarding billing, customer support, resolving complaints and quality control.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            inform you about changes to our Platforms, products and services, terms and conditions, Privacy Policy or notices.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            subject to and in accordance with our Platform terms and conditions including promotional competition terms and conditions published on the Platform/s from time to time, and provided you have purchased an SFT/or received a relevant code, enter your relevant details into our in-app Money-Reward lucky draw.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            send you information about other in-app promotional competitions, products or services that may interest you regarding our Platforms (unless you have opted out of direct marketing communications).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            get feedback from you in order to improve our Platforms and existing products and services, develop new products and services and grow our business.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            comply with any legal or regulatory obligations such as tax, financial laws etc.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            carry out statistical and other research for instance to identify potential markets and trends. This process will however anonymise/de-identify any personal information and cannot be linked to you.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>RETENTION AND RESTRICTION OF RECORDS</p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We keep your personal information so that we may continue providing our Platforms, products services to you, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Policy; as may be required by law (including for tax and accounting purposes); for as long as it may be necessary uphold the contract between you and us. How long we retain specific Personal Information varies depending on the purpose for its use, our internal retention policies. We will delete information in accordance with applicable law.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>USER TRACKING AND COOKIES</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We use technological tools to improve your experience on our Platforms through the use of cookies and/or user tracking. A cookie is a small text file that is stored on your device when you visit a website or use an app. The files contain specific information related to your use of our Platforms, such as your login details, preference settings and tracking identifiers
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Cookies assist us in personalising your interactions with our Platforms, tailor our services to your interests and needs, and ensure they work on your device, provide advertising that is relevant to you, limit the number of times you see the same advertisement and measure the effectiveness of the advertisements on our Platforms, based on your online activities on other websites, or in relation to the Platforms or content you view on the Platforms, and information we have about you.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>By accessing and using the Platform, you consent to our use of cookies.</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We would from time to time like to share information with you about our Platforms, own products and services or about promotions on similar products and services used by you, using your indicated preferred method of communication such as email, text message, mobile phone etc.  If you have opted in to receive marketing communications, you may always opt out by clicking on the “unsubscribe” or “opt out” option included in our marketing communications. You may also contact us on <a href='mailto:support@choohoo.world' className='text-[#ebf411]' target='blank'>support@choohoo.world</a> for us to note that you have indicated “No” to receiving direct marketing material.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>We do not sell your information to third parties for any purpose whatsoever..</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may appoint individuals or companies to assist us in carrying out certain functions for the operation and provision of our Platforms, products and services provided through our Platforms, such as:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            the marketing of SFT’s
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            sending communications
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            processing payments
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            assessing compliance risks
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            analysing data
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            conducting customer relationship management
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            conducting training
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        These third party service providers have access to personal information needed to perform their functions, but may not use it for any other purposes and must treat it as confidential. Further, they must process that information in accordance with this Privacy Policy and in accordance with applicable data protection laws.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may further provide or make information available to:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            our employees, and/or third party service providers in order to enable them to assist us to interact with you via our Platforms for the marketing, sales, digital delivery of SFT’s, or operational reasons or regarding promotions, (excluding direct marketing if you have opted out of same).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            law enforcement, government officials, fraud detection agencies or other third parties when the disclosure of personal information is necessary or appropriate in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us to legal liability or financial loss, to report or support the investigation into suspected illegal activity.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            third parties (such as a potential purchaser and its professional advisors) in the event of any reorganisation, merger, consolation, sale, joint venture, or other disposition of any or all of our assets.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            service providers contracted who to assist with parts of our business operations (such as fraud prevention, public relations etc.) However, our contracts dictate that these service providers may only use your information in connection with the services they perform for us and not for their own benefit or any other purpose, and must treat such information as confidential.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            any suppliers in order for them to liaise directly with you regarding any products or services made available through our Platforms, or for any other purpose which may require their involvement for purposes of the Platforms.
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>At your Option</u>: other than as set out in this Privacy Policy, you will receive notice when Personal Information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        <u>If you are located in the European Union (EU) or the United Kingdom (UK), please note:</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The General Data Protection Regulation (GDPR) and UK GDPR requires us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Consent.</u>  We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. (For instance, see ‘HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING’ above).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Performance of a Contract.</u> We process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Platforms, products or services, related partnership opportunities made available through our Platforms (creative partnerships, sales and marketing partnerships or at your request prior to entering into a contract with
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Legitimate Interests.</u> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            send you information about special offers and on our products and services (unless you have unsubscribed/opted out of direct marketing).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            develop and then display (in-app) personalised and relevant advertising content.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            analyse how our services are used so we can improve them to engage and retain users.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            support our marketing activities.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            diagnose problems and/or prevent fraudulent activities.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            understand how our users use our products and services so we can improve user experience.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-28 mt-0'>
                            marketing
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Legal Obligations.</u> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            <u>Vital Interests.</u> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        <u>If you are located in Canada, please note:</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. (For instance, see ‘HOW WE USE YOUR PERSONAL INFORMATION FOR DIRECT MARKETING’ above’).
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In some exceptional cases, we may be legally permitted under applicable law to  process your information without your consent, including, for example:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For investigations and fraud detection and prevention.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For business transactions provided certain conditions are met.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            For identifying injured, ill, or deceased persons and communicating with next of kin.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If the collection is solely for journalistic, artistic, or literary purposes.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If the information is publicly available and is specified by the regulations.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>IS YOUR PERSONAL INFORMATION TRANSFERRED INTERNATIONALLY?</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may transfer, store, and process your personal information in countries other than your own. We do this for the purposes set out above (see ‘WHY WE PROCESS YOUR PERSONAL INFORMATION’ above), including for data storage and back-up purposes to ensure the integrity of our systems.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        When we transfer/store/process your Personal Information cross border/internationally, we will ensure that we do so in accordance with the requirements for lawful transfer as set out in applicable data privacy/protection laws.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Our servers are located in South Africa. If you are accessing our Services from outside South Africa, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see ‘WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION’ above), in South Africa, United States, Germany, Dubai, Australia, Brazil, United Kingdom, Spain, France, Italy, Austria, Belgium, Bulgaria, Finland, Ireland, Greece, Hungary, Cyprus, Czech Republic, Latvia, Luxembourg, Denmark, Estonia, Malta, Netherlands, Portugal, Romania, Slovakia, Slovenia, Sweden, and other countries.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are a resident in the European Economic Area (‘EEA’) or the UK, then these countries may not necessarily have data protection/privacy laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this Privacy Policy and applicable laws.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The European Commission’s Standard Contractual Clauses:
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third- party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        By accessing and using our Platforms, you consent to us transferring, storing and processing your Personal Information internationally in accordance with this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>HOW WE SECURE YOUR PERSONAL INFORMATION</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We secure the integrity and confidentiality of your personal information in our possession or under our control by taking appropriate, reasonable technical and organisational measures to prevent loss of, damage to or unauthorised destruction of personal information and unlawful access to or processing of personal information.
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In order to implement and maintain such measures, we have policies, controls and related processes, which are reviewed regularly. These include:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            physical, technical and network security.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            access controls and monitoring of access.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            secure storage, destruction and encryption of records of Personal Information.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            personal information breach reporting and remediation.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            written security and confidentiality obligations placed on third parties (based within or outside the borders of South Africa) who process personal information as part of rendering services to us.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal italic'>
                        Should you disclose your Personal Information to any third party other than us, we shall not be liable for any loss or damage arising or suffered by you as a result of the disclosure of such Information to any third party. This is because we do not regulate or control how that third party uses your personal information. You should always ensure that you read the privacy policy of any third party.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>YOU HAVE THE RIGHT TO:</p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            view, correct or amend your personal information. Registered users can do this through their user account for the personal information reflected therein.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            request a record or description of your personal information.  We may charge a fee in order to provide you with this record. Where requests to access and amend your personal information are self-evidently unfounded, excessive/repetitive we may charge an additional administrative fee or refuse the request.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are a resident of California, you are granted specific rights regarding access to your personal information:
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin ml-12'>
                        The California Civil Code Section 1798.83, also known as the ‘Shine The Light’ law, permits users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            request to have your personal information corrected, destroyed or deleted. <b>You can stop being a registered user by cancelling your account. In this instance we will only retain your Personal Information subject to any legislative requirement and/or our internal retention policy.</b>
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            us complying with your requests upon receipt unless we have credible reason why we cannot comply.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            us indicating where, if we cannot agree whether to correct or delete your personal information as requested, a correction or deletion was requested but not made.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            inform you if reasonably practicable,  should we change your personal information and this has an impact on decisions about you.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            notify you of the action taken by us because of your request.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            notify you of unauthorised access to your Personal Information.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            provide you with reasonable evidence of our compliance with our obligations under this Privacy Policy on reasonable notice and written request.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-12'>
                            If you located in South Africa and you believe we are unlawfully processing your personal information, you may submit a complaint to the Information Regulator whose contact details can be found on its website at: <a href='https://www.inforegulator.org.za' target='_blank' className='text-[#ebf411]'>www.inforegulator.org.za</a>
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
                        </li>
                    </ul>
                    <p className='text-[22px] md:text-[24px] font-thin overflow-hidden ml-12'>
                        <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm' target='_blank' className='text-[#ebf411]'>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            If you are located in Switzerland, the contact details for the data protection authorities are available here:
                        </li>
                    </ul>
                    <p className='text-[22px] md:text-[24px] font-thin overflow-hidden ml-12'>
                        <a href='https://www.edoeb.admin.ch/edoeb/en/home.html' target='_blank' className='text-[#ebf411]'>https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            you can exercise all your rights as set out above by referring to our process set out in our PAIA Manual, <a href='https://www.choohoo.world/document/Choohoo_PAIA_Manual.pdf' target='_blank' className='text-[#ebf411]'>here</a>. (‘PAIA’ refers to the South African statute – Promotion of Access to Information Act 2 of 2000).
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you have questions or comments about this Privacy Policy, you may contact our support services by email at support@choohoo.world or by post to PO Box 2098 Clareinch 7740, Cape Town South Africa.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>CALIFORNIA RESIDENTS’ SPECIFIC PRIVACY RIGHTS</p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            If you are a resident of California, you are granted specific rights regarding access to your personal information:
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            The California Civil Code Section 1798.83, also known as the ‘Shine The Light’ law, permits users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing addressing it to our support services at support@choohoo.world.
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-normal ml-12 mt-12'>
                            <b className='font-bold'>The California Consumer Privacy Act (CCPA) Privacy Notice</b>
                        </li>
                    </ul>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The California Code of Regulations defines a ‘resident’ as:
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        (1) every individual who is in the State of California for other than a temporary or transitory   purpose and
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose.
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        All other individuals are defined as ‘non-residents’. If this definition of ‘resident’ applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    </p>
                    <p className='text-[18px] md:text-[22px] font-normal mt-4'>
                        What categories of personal information do we collect?
                    </p>
                </div>

                <div className='mt-11 ml-12'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We have collected the following categories of personal information in the past twelve 12 months:
                    </p>
                </div>

                {/* Table Content */}

                <div className='mt-11 ml-12'>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-normal w-1/5'>Category</th>
                        <th className='text-[18px] md:text-[22px] font-normal'>Examples</th>
                        <th className='text-[18px] md:text-[22px] font-normal w-1/24'>Collected</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>A. Identifiers</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>B. Personal information categories listed in the California Customer <br /><br />Records statute</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Name, contact information, education, employment, employment history, and financial information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>C. Protected classification <br /><br />characteristics under California or federal law</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Gender and date of birth</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>D. Commercial Information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Transaction information, purchase history, financial details, and payment information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>E. Internet and other similar network activity</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>F. Geolocation data</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Device location</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>G. Audio, electronic, visual or similar information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Images and audio, video  created in connection with our business activities</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>H. Professional or employment-related information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>I. Education Information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Student records and directory information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                    <tr>
                        <th className='text-[18px] md:text-[22px] font-thin'>J. Inferences drawn from other personal information</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and Characteristics</th>
                        <th className='text-[18px] md:text-[22px] font-thin'>NO</th>
                    </tr>
                </div>


                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may also collect other personal information outside of these categories, instances where you interact with us in person, online, or by phone or mail in the context of:
                    </p>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-4'>
                            Receiving help through our customer support channels;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Participation in customer surveys or contests; and
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Facilitation in the delivery of our products and services and to respond to your inquiries.
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>How do we use and share your personal information?</p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Choohoo Proprietary Limited collects and shares your personal information through:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            Targeting cookies/Marketing cookies
                        </li>
                    </ul>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        More information about our data collection and sharing practices can be found in this Privacy Policy.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may contact us by email at <a href='mailto:support@choohoo.world' target='_blank' className='text-[#ebf411]'>support@choohoo.world</a> or by referring to the contact details the bottom of this document.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        If you are using an authorised agent to exercise your right to opt out we may deny a   request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-normal mt-2'>
                        Will your information be shared with anyone else?
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is either a for- profit entity or a non-profit entity that processes the information on our behalf.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be ‘selling’ of your personal information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Choohoo Proprietary Limited has not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Choohoo Proprietary Limited has not disclosed any of the above tabulated categories of personal information to third parties for business or commercial purposes in the preceding twelve (12) months.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        The categories of third parties to whom we may disclose personal information for a business or commercial purposes can be found under ‘WHO ELSE MAY PROCESS YOUR PERSONAL INFORMATION’ above.
                    </p>
                    <p className='text-[18px] md:text-[22px] font-normal mt-2'>
                        Your rights with respect to your personal data
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Right to request deletion of the data — Request to delete</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Right to be informed — Request to know</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Depending on the circumstances, you have a right to know:
                    </p>
                </div>

                <div className='mt-11'>
                    <ul>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            whether we collect and use your personal information;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of personal information that we collect;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the purposes for which the collected personal information is used;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            whether we sell your personal information to third parties;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of personal information that we disclosed for a business purpose;
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the categories of third parties to whom the personal information was disclosed for a business purpose; and
                        </li>
                        <li className='list-disc text-[22px] md:text-[24px] font-thin ml-12 mt-0'>
                            the business or commercial purpose for collecting personal
                        </li>
                    </ul>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re- identify individual data to verify a consumer request.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy</u>
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-12'>
                        <u>Rights</u>
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-12'>
                        We will not discriminate against you if you exercise your privacy rights.
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        <u>Verification process</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        <u>Other privacy rights</u>
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may object to the processing of your personal information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        To exercise these rights, you can contact us by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[24px] font-normal'>
                        UPDATING THIS PRIVACY POLICY
                    </p>
                    <br />
                    <p className='text-[22px] md:text-[24px] font-thin'>
                        We may update this Privacy Policy from time to time. When you use our Platforms the version of the Privacy Policy posted on this page applies to you. The updated version will be indicated by an updated ‘Revised’ date. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    </p>
                </div>

                <div className='mt-11'>
                    <p className='text-[22px] md:text-[2.5rem] font-extrabold'>
                        HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </p>
                    <p className='text-[22px] md:text-[24px] font-thin mt-2'>
                        If you have questions or comments about this policy, you may contact our support services by email at <a href='mailto:support@choohoo.world' target='_blank' className='text-[#ebf411]'>support@choohoo.world</a> or by post to: Choohoo Proprietary Limited, PO Box 2098 Clareinch, 7740 Cape Town South Africa.
                    </p>
                </div>

                <div className='mt-16 mb-8 text-[14px] text-center font-light'>
                    <p>Copyright © CHOOHOO (PTY) LTD 2022. All Rights Reserved. Cape Town, South Africa.</p>
                </div>

            </div>
        </div>
    )
}
export default PrivacyPolicy;

