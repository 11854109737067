import React from 'react'
import { Helmet } from 'react-helmet-async';
import logo from '../assets/logo.png'

function NotFound() {
    return (
        <div>
            <Helmet>
                <title>Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits.</title>
                <meta name="description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
                <meta property="og:title" content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits." />
                <meta property="og:description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
            </Helmet>
            <div className='flex content-center justify-center'>
                <a href='/'>
                    <img src={logo} alt='logo' className='w-52 mt-10' />
                </a>
            </div>

            <div className="flex flex-col w-full h-auto justify-center items-center">
                <div className="font-linowrite 2xl:mt-52 xl:mt-36 lg:mt-40 mt-20 text-7xl md:text-9xl xl:tracking-[16px] lg:tracking-[12px] tracking-[8px] text-center text-[#ebf411]">
                    404
                    <br />
                    <p className="text-3xl mt-4">NOT FOUND</p>
                </div>
            </div>
        </div>
    )
}

export default NotFound;