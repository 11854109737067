import axios from 'axios';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import logo from '../assets/logo.png'
import Modal from "react-modal";
import { MdOutlineCancel } from "react-icons/md";
import { Helmet } from 'react-helmet-async';

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: 'black',
        boxShadow: 'white'
    },
    overlay: {
        backgroundColor: 'transparent'
    }
};

function Unsubscribe() {
    const { user_id, choohoo_id, fname, lname } = useParams();
    const [user, setUser] = useState(fname + ' ' + lname + '(' + choohoo_id + ')');

    const [formErro, setFormError] = useState(null);
    const [formRes, setFormRes] = useState(null);

    const [modalIsOpen, setIsOpen] = useState(false);

    const formHandle = (e) => {
        e.preventDefault();

        openModal();
    }

    function unsubscribe() {
        let formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('choohoo_id', choohoo_id);

        axios.post(`https://choohoo.tech/api/unsbscribe/users`, formData)
            .then(res => {

                closeModal();
                if (res !== undefined) {
                    setFormError(null);
                    setFormRes(res.data.message);
                }

            })
            .then(err => {
                closeModal();
                if (err !== undefined) {
                    setFormError(err.data.message);
                    setFormRes(null);
                }
            });
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <Helmet>
                <title>Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits.</title>
                <meta name="description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
                <meta property="og:title" content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits." />
                <meta property="og:description" content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler" />
            </Helmet>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="confirm dialog"
            >
                <div>
                    <div className="flex justify-between items-center">
                        <p className="font-bold text-lg text-white">Are you sure?</p>
                        <button
                            type="button"
                            onClick={closeModal}
                            className="text-xl p-2 hover:drop-shadow-md hover:bg-slate-500 rounded-[50%] text-white"
                        >
                            <MdOutlineCancel />
                        </button>
                    </div>
                    <div className='flex flex-col mt-4 mr-4 mb-4'>
                        <div className='flex'>
                            <p>Are you sure you want to Unsubscribe?</p>
                        </div>
                        <div className="flex justify-between items-center mt-5">
                            <button
                                className="bg-[#6e7585] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
                                onClick={closeModal}
                            >
                                No
                            </button>
                            &nbsp;
                            <button
                                onClick={unsubscribe}
                                className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className='flex content-center justify-center'>
                <a href='/'>
                    <img src={logo} alt='logo' className='w-52 mt-10' />
                </a>
            </div>

            <div className='w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto'>
                <div className='mt-16 text-center font-thin text-[22px] md:text-2xl lg:text-[26px]'>
                    <p className='ml-5 mr-5'>Should you choose to unsubscribe from this electronic communication service, you will be unsubscribing from the Choohoo service and opting out of any further Choohoo electronic communications.</p>
                </div>
                <div className='xl:w-2/4 m-auto lg:w-2/4 md:w-3/4'>
                    <form onSubmit={formHandle}>
                        <div className='flex flex-row ml-3 mr-3 mt-11'>
                            <label className='h-12 pt-3'>User:*</label>&nbsp;&nbsp;
                            <input type='text' id='user' value={user} onChange={(e) => { setUser(e.target.value) }} required className='h-12 rounded-full bg-black text-[16px] md:text-[22px] pl-5 flex-row w-full p-4 text-gray-400 border border-[#ebf411] sm:text-md focus:ring-blue-700 focus:border-blue-700 focus:bg-white dark:bg-black dark:border-[#ebf411] dark:placeholder-gray-400 dark:text-gray-400' readOnly />
                        </div>
                        <div className='mt-11 text-[30px] bg-[#ebf411] w-[96%] flex content-center justify-center m-auto rounded-full text-black'>
                            <button type='submit'>UNSUBSCRIBE</button>
                        </div>
                        <div className='mt-8 text-[16px] w-full flex content-center justify-center m-auto rounded-lg'>
                            <p className={`${formErro ? 'show' : 'hidden '} border-2 border-dashed border-slate-500 p-3 text-red-300`}>{formErro}</p>
                            <p className={`${formRes ? 'show' : 'hidden '} border-2 border-dashed border-slate-500 p-3 text-white`}>{formRes}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Unsubscribe;