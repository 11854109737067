import React, { useState } from "react";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import MediaRelease from "../assets/Choohoo Final Media Release.pdf";

function Sidebar() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {!isOpen ? (
        <Menu
          fontSize="large"
          className="ml-5 mt-5 cursor-pointer fixed"
          onClick={() => setOpen(!isOpen)}
        />
      ) : (
        <div>
          <Close
            fontSize="large"
            color="disabled"
            className="mt-4 ml-4 fixed cursor-pointer z-50"
            onClick={() => {
              setOpen(!isOpen);
            }}
          />
        </div>
      )}

      <div
        className={`top-0 left-0 fixed bg-white md:w-[50vw] xl:w-[25vw] lg:w-[40vw] h-full p-10 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } ease-in-out duration-300 z-40`}
      >
        <div className="ml-5 text-[18px] mt-4 text-black">
          <div>
            <div className="">
              <a
                href="/"
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                Home
              </a>
            </div>
            <div className="pt-[20px]">
              <a
                href="/#form"
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                Fill in the form
              </a>
            </div>
            <div className="pt-[20px]">
              <a
                href="/#sft"
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                What are SFTs?
              </a>
            </div>
            <div className="pt-[20px]">
              <a
                href="/#marketplace"
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                Why is Choohoo a Global Shared Marketplace?
              </a>
            </div>
            <div className="pt-[20px]">
              <a
                target="_blank"
                href={MediaRelease}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                Media Release
              </a>
            </div>

            <hr className="divide-y-8 border-solid  h-[1px] bg-black border-black mt-5"></hr>

            <div className="pt-[20px]">
              <a
                href="/privacy-policy"
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
