import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Helmet } from "react-helmet-async";

function MagicLink() {
  const { token, user_id, buy_id, ref } = useParams();

  const [perc, setPerc] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState();
  const [sftName, setSFTName] = useState();
  const [userName, setUserName] = useState();

  const [total, setTotal] = useState(0);
  const [down, setDown] = useState(0);

  useEffect(() => {
    getSFT();
  }, []);

  function getSFT() {
    const data = {
      user_id: user_id,
      buy_id: buy_id,
      token: token,
    };

    axios
      .post(`https://choohoo.tech/api/magic-url/read`, data)
      .then((response) => {
        if (response.data.success) {
          setStatus(1);
          setSFTName(response.data.output.sft_name);
          setUserName(response.data.output.artist_name);
          download(response.data.output.url, response.data.output.sft_name);
        } else {
          setStatus(2);
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setStatus(2);
        setMessage(error.message);
      });
  }

  const download = async (url, name) => {
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        setTotal((progressEvent.total / 1000000).toFixed(2));
        setDown((progressEvent.loaded / 1000000).toFixed(2));
        setPerc(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    }).then((response) => {
      let array = response.config.url.split('/');
      let fullname = array.pop();
      let filename = fullname.split('.')[0];

      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + '_' + ref;
      a.click();
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You,
          creative work and public benefits.
        </title>
        <meta
          name="description"
          content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler"
        />
        <meta
          property="og:title"
          content="Choohoo | it's for yoo hoo | A Shared Marketplace that Rewards You, creative work and public benefits."
        />
        <meta
          property="og:description"
          content="Partner with the world's first SFT marketplace, and join this global opportunity for shared revenue as a creative, social cause, marketer or side-hustler"
        />
      </Helmet>
      <div className="flex content-center justify-center">
        <a href="/">
          <img src={logo} alt="logo" className="w-52 mt-10" />
        </a>
      </div>

      {status === 0 ? (
        <div className="w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto">
          <div className="mt-16 text-center font-light text-[22px] md:text-2xl lg:text-[26px] text-[#ebf411]">
            Loading...
          </div>
        </div>
      ) : status === 1 ? (
        <div className="w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto">
          <div className="mt-16 text-center font-thin text-[22px] md:text-2xl lg:text-[26px]">
            <CircularProgressbar
              className="h-[180px] w-[180px]"
              value={perc}
              text={<tspan className="text-xl font-medium">{perc}%</tspan>}
              styles={{
                path: {
                  stroke: "#EBF411",
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                },
                trail: {
                  stroke: "#707070",
                },
                text: {
                  fill: "#EBF411",
                  fontSize: "16px",
                },
                background: {
                  fill: "#3e98c7",
                },
              }}
            />
            {perc < 100 ? (
              <div className="mt-4 text-2xl font-medium text-[#ebf411]">
                Downloading...
              </div>
            ) : (
              <div className="mt-4 text-2xl font-medium text-[#ebf411]">
                Downloaded
              </div>
            )}
          </div>
          <div className="xl:w-2/4 m-auto lg:w-2/4 md:w-3/4">
            <div className="flex flex-col mt-11 text-3xl font-light content-center text-center">
              <p className="font-[550]">{sftName}</p>
              <p className="font-light">by {userName}</p>
            </div>
            <div className="flex flex-col mt-11 text-3xl font-light content-center text-center">
              <p>
                {down} MB of {total} MB downloaded
              </p>

              {perc === 100 ? (
                <>
                  <p>Download complete.</p>
                  <div className="mt-12">Enjoy :-)</div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-11/12 md:w-11/12 lg:w-11/12 xl:w-[1140px] m-auto">
          <div className="mt-16 text-center text-[22px] md:text-2xl lg:text-[26px] text-red-600 font-light">
            {message}
          </div>
        </div>
      )}
    </div>
  );
}

export default MagicLink;
