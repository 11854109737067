
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import Sidebar from './components/Sidebar';
import Unsubscribe from './components/Unsubscribe';
import Terms from './components/Terms';
import MagicLink from './components/MagicLink';
import NotFound from './components/404';
import SFT from './components/SFT';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <div>

        <Routes>
          <Route exact path="/terms" element={<Terms />}></Route>
        </Routes>
        <Sidebar />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/*" element={<NotFound />}></Route>
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route exact path="/unsubscribe/:user_id/:choohoo_id/:fname/:lname" element={<Unsubscribe />}></Route>
          <Route exact path="/magic_link/:token/:user_id/:buy_id/:ref" element={<MagicLink />}></Route>
          <Route exact path="/sft/:sft_id" element={<SFT />}></Route>
        </Routes>

      </div>
    </HelmetProvider>
  );
}

export default App;
