import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

function SFT() {
    const { sft_id } = useParams();
    const [pod, setPod] = useState(null);

    useEffect(() => {
        loadSFTs();
    }, []);

    function loadSFTs() {
        const formData = new FormData();
        formData.append('user_id', 0);
        formData.append('user_type_id', 0);

        axios.post(`https://choohoo.tech/api/sft/list`, formData)
            .then(res => {
                if (res.data.output.length !== 0) {
                    res.data.output.forEach((sft) => {
                        if (sft.pod_number === sft_id) {
                            setPod(sft);
                        }
                    });
                }
            })
            .then(err => {
                console.log(err)
            });
    }

    return (
        <div>
            <Helmet>
                <title>{pod?.sft_name}</title>
                <meta name="title" content={pod?.sft_name} />
                <meta name="description" content={pod?.sft_specs !== null ? pod?.sft_specs : ''} />
                <meta property="og:title" content={pod?.sft_name} />
                <meta property="og:description" content={pod?.sft_specs !== null ? pod?.sft_specs : ''} />
                <meta property="og:image" content={pod?.sft_thumbnail} />
                <meta property="og:image:width" content="1080"></meta>
                <meta property="og:image:height" content="1080"></meta>
                <meta property="og:url" content={"https://choohoo.world/" + pod?.pod_number} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content='summary_large_image' />
                <meta name="twitter:title" content={pod?.sft_name} />
                <meta name="twitter:description" content={pod?.sft_specs !== null ? pod?.sft_specs : ''} />
                <meta name="twitter:image" content={pod?.sft_thumbnail} />
                <meta name="twitter:url" content={"https://choohoo.world/" + pod?.pod_number} />
            </Helmet>
            <div>
                <img src={pod?.sft_thumbnail} />
            </div>
        </div>
    )
}
export default SFT;